import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  getUserName,
  getTenantName,
  getHeaderMode,
  getProjectName,
} from './selectors';
import View from './view';

import './style.css';

const Header = props => {
  const [width, setWidth] = useState(0);
  
  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return function cleanup() {
      window.removeEventListener('resize', updateWindowDimensions);
    }
  }, []);
  
    const { auth, userName, tenantName, headerMode, projectName } = props;
    return (
      <View
        auth={auth}
        userName={userName}
        tenantName={tenantName}
        headerMode={headerMode}
        projectName={projectName}
        screenWidth={width}
      />
    );
}

let mapStateToProps = state => ({
  userName: getUserName(state),
  tenantName: getTenantName(state),
  headerMode: getHeaderMode(state),
  projectName: getProjectName(state),
});

export default connect(
  mapStateToProps,
  null
)(Header);

export const GET_WORKSPACE_START = 'GET_WORKSPACE_START';
export const GET_WORKSPACE_SUCCESS = 'GET_WORKSPACE_SUCCESS';
export const GET_WORKSPACE_FAILURE = 'GET_WORKSPACE_FAILURE';
export const ADD_PROPOSAL_START = 'ADD_PROPOSAL_START';
export const ADD_PROPOSAL_SUCCESS = 'ADD_PROPOSAL_SUCCESS';
export const ADD_PROPOSAL_FAILURE = 'ADD_PROPOSAL_FAILURE';
export const UPDATE_PROPOSAL_START = 'UPDATE_PROPOSAL_START';
export const UPDATE_PROPOSAL_SUCCESS = 'UPDATE_PROPOSAL_SUCCESS';
export const UPDATE_PROPOSAL_FAILURE = 'UPDATE_PROPOSAL_FAILURE';
export const COPY_PROPOSAL_START = 'UPDATE_PROPOSAL_START';
export const COPY_PROPOSAL_SUCCESS = 'UPDATE_PROPOSAL_SUCCESS';
export const COPY_PROPOSAL_FAILURE = 'UPDATE_PROPOSAL_FAILURE';
export const RENAME_PROPOSAL_START = 'RENAME_PROPOSAL_START';
export const RENAME_PROPOSAL_SUCCESS = 'RENAME_PROPOSAL_SUCCESS';
export const RENAME_PROPOSAL_FAILURE = 'RENAME_PROPOSAL_FAILURE';
export const DELETE_PROPOSAL_START = 'DELETE_PROPOSAL_START';
export const DELETE_PROPOSAL_SUCCESS = 'DELETE_PROPOSAL_SUCCESS';
export const DELETE_PROPOSAL_FAILURE = 'DELETE_PROPOSAL_FAILURE';
export const DISCARD_PROPOSAL_CHANGES = 'DISCARD_PROPOSAL_CHANGES';
export const CLEAR_WORKSPACE = 'CLEAR_WORKSPACE';
export const CHANGE_ADJUSTMENT_VALUE = 'CHANGE_ADJUSTMENT_VALUE';
export const CALCULATE_PROPOSALS_START = 'CALCULATE_PROPOSALS_START';
export const CALCULATE_PROPOSALS_SUCCESS = 'CALCULATE_PROPOSALS_SUCCESS';
export const CALCULATE_PROPOSALS_FAILURE = 'CALCULATE_PROPOSALS_FAILURE';
export const CALCULATE_PROPOSALS_START_QUEUED =
  'CALCULATE_PROPOSALS_START_QUEUED';
export const CHANGE_CENSUS_FILE = 'CHANGE_CENSUS_FILE';
export const CHANGE_POOLED_CENSUS_FILE = 'CHANGE_POOLED_CENSUS_FILE';
export const CHANGE_HEALTHCARE_FILE = 'CHANGE_HEALTHCARE_FILE';
export const CHANGE_SALARY_SCHEDULE = 'CHANGE_SALARY_SCHEDULE';
export const CHANGE_PAY_GRADES = 'CHANGE_PAY_GRADES';
export const CHANGE_PARTIAL_ALLOCATIONS = 'CHANGE_PARTIAL_ALLOCATIONS';

import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from '@material-ui/icons/Settings';
import MyPopper from '../common/my-popper';

const AdminOptionsIconWrapper = styled.div`
  transform: ${props => (props.open ? 'rotate(30deg) translateZ(0)' : 'translateZ(0)')};
  transition: 0.3s ease;
`;

const AdminOptionsIcon = styled(SettingsIcon)`
  color: var(--color-theme-primary);
  margin-top: 6px;
  cursor: pointer;
  &:hover {
    color: var(--color-theme-primary-d);
  }
`;

const AdminMenu = () => {
  const [open, setOpen] = useState(false);

  return (
    <MyPopper
      closeOnClick={true}
      popperOpenedCallback={setOpen}
      titleComponent={
        <AdminOptionsIconWrapper open={open}>
          <AdminOptionsIcon />
        </AdminOptionsIconWrapper>
      }
      poppedComponent={
        <MenuList role="menu">
          <Link to="/app/admin/tenants">
            <MenuItem>Tenants</MenuItem>
          </Link>
          <Link to="/app/admin/app-info">
            <MenuItem>App Info</MenuItem>
          </Link>
          <Link to="/app/admin/notification-controls">
            <MenuItem>Notifications</MenuItem>
          </Link>
        </MenuList>
      }
    />
  );
};

export default AdminMenu;

import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandButton from '../common/expand-button';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { arrayReorder, copyToClipboard } from '../../lib/util';
import { convertChartDataToTable } from '../workspace/results/chartDataBuilder';
import {
  formatDollarValue,
  formatPercentValue,
} from '../../shared/chartUtility';

const StyledTableCell = styled(TableCell)`
  color: white !important;
  background: #3e4156;
`;

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: #fafafa;
  },
`;

const ChartIcons = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  justify-content: flex-end;
  align-items: center;
`;

const StyledCopyIcon = styled.a`
  color: var(--color-grey-d);
  font-size: 18px !important;
  margin-top: 3px;
  transition: all 0.05s linear;
  &:hover {
    color: var(--color-theme-primary);
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 0,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

class TableChart extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.calculateOnUpdate) {
      return true;
    } else {
      if (nextProps.lastUpdateType === 'calculation') {
        return true;
      } else {
        return false;
      }
    }
  }

  render() {
    const { classes, chartData, currentProposalImpactName } = this.props;

    const tableChartData = convertChartDataToTable(chartData);
    const tableHeader = tableChartData[0];
    tableChartData.splice(0, 1);
    const dataRows = tableChartData;
    const rowHeaders = dataRows.map(x => x[0]);
    let rowData = dataRows.map(x => {
      x.splice(0, 1);
      return x;
    });

    if (tableHeader.includes(currentProposalImpactName)) {
      arrayReorder(tableHeader, tableHeader.length - 1, 2);
      rowData.forEach(row => arrayReorder(row, row.length - 1, 1));
    }

    //this is a bit hacky.  we look at the column name to determine whether it's a pct
    const pctHeaders = tableHeader.map(x => x.indexOf('% Difference') !== -1 || x.indexOf('(%)') !== -1);
    //we are doing this to format the Account Description column
    const textHeaders = tableHeader.map(x => x.indexOf('Description') !== -1);
    const formatCell = (c, n) => {
      if (pctHeaders[n + 1] === true) {
        return formatPercentValue(c);
      }
      if (textHeaders[n + 1] === true) {
        return c;
      }
      return formatDollarValue(c);
    };

    return (
      <React.Fragment>
        {!this.props.printView && (
          <ChartIcons>
            <StyledCopyIcon onClick={() => copyToClipboard('hidden-table')} title="Copy data">
              <FontAwesomeIcon icon="copy" />
            </StyledCopyIcon>
            <ExpandButton />
          </ChartIcons>
        )}
        <Paper className={classes.root}>
          <div style={{ height: '0px', zIndex: -1 }}>
            <Table
              id="hidden-table"
              style={{ opacity: 0 }}
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  {tableHeader
                    .slice(0, 6)
                    .map(h => <TableCell key={uuidv4()}>{h}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataRows.map((r, n) => {
                  return (
                    <TableRow key={uuidv4()}>
                      <TableCell component="th" scope="row">
                        {rowHeaders[n]}
                      </TableCell>
                      {rowData[n]
                        .slice(0, 5)
                        .map((c, n) => <TableCell key={uuidv4()}>{c}</TableCell>)}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {tableHeader
                  .slice(0, 6)
                  .map(h => (
                    <StyledTableCell key={uuidv4()}>
                      {h.replace(/_/g, ' ')}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataRows.map((r, n) => {
                return (
                  <StyledTableRow key={uuidv4()}>
                    <TableCell component="th" scope="row">
                      {rowHeaders[n]}
                    </TableCell>
                    {rowData[n]
                      .slice(0, 5)
                      .map((c, n) => (
                        <TableCell key={uuidv4()}>{formatCell(c, n)}</TableCell>
                      ))}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TableChart);

import { toast } from 'react-toastify';
import { baseURL } from '../../lib/config';
import Auth from '../../auth/auth';

export function downloadUserGuide() {
  const toastId = toast('Downloading User\'s Guide...', {
    autoClose: 180000,
    type: toast.TYPE['SUCCESS'],
  });

  new Auth().checkAuth();
  return fetch(`${baseURL()}/api/user-guide-request`, {
    method: 'GET',
    credentials: 'include',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then(response => {
      if (!response.ok) {
        throw Error('Failed to download User\'s Guide.');
      }
      return response;
    })
    .then(res => res.json())
    .then(async getResponse => {
      const link = document.createElement('a');
      link.href = getResponse.downloadUri;
      link.click();

      const updateToast = {
        toastId,
        render: 'User\'s Guide downloaded',
        type: toast.TYPE['SUCCESS'],
        autoClose: 2000,
      };
      toast.update(toastId, updateToast);
    })
    .catch(e => {
      const updateToast = {
        toastId,
        render: e,
        type: toast.TYPE['ERROR'],
        autoClose: 2000,
      };
      toast.update(toastId, updateToast);
    });
}

export function downloadReleaseNotes() {
  const toastId = toast('Downloading Release Notes...', {
    autoClose: 180000,
    type: toast.TYPE['SUCCESS'],
  });

  new Auth().checkAuth();
  return fetch(`${baseURL()}/api/release-notes-request`, {
    method: 'GET',
    credentials: 'include',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then(response => {
      if (!response.ok) {
        throw Error('Failed to download Release Notes.');
      }
      return response;
    })
    .then(res => res.json())
    .then(async getResponse => {
      const link = document.createElement('a');
      link.href = getResponse.downloadUri;
      link.click();

      const updateToast = {
        toastId,
        render: 'Release Notes downloaded',
        type: toast.TYPE['SUCCESS'],
        autoClose: 2000,
      };
      toast.update(toastId, updateToast);
    })
    .catch(e => {
      const updateToast = {
        toastId,
        render: e,
        type: toast.TYPE['ERROR'],
        autoClose: 2000,
      };
      toast.update(toastId, updateToast);
    });
}

export function downloadEligibilityTips() {
  const toastId = toast('Downloading Eligibility Tips...', {
    autoClose: 180000,
    type: toast.TYPE['SUCCESS'],
  });

  new Auth().checkAuth();
  return fetch(`${baseURL()}/api/elig-tips-request`, {
    method: 'GET',
    credentials: 'include',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then(response => {
      if (!response.ok) {
        throw Error('Failed to download Eligibility Tips.');
      }
      return response;
    })
    .then(res => res.json())
    .then(async getResponse => {
      const link = document.createElement('a');
      link.href = getResponse.downloadUri;
      link.target = "_blank";
      link.click();

      const updateToast = {
        toastId,
        render: 'Eligibility Tips downloaded',
        type: toast.TYPE['SUCCESS'],
        autoClose: 2000,
      };
      toast.update(toastId, updateToast);
    })
    .catch(e => {
      const updateToast = {
        toastId,
        render: e,
        type: toast.TYPE['ERROR'],
        autoClose: 2000,
      };
      toast.update(toastId, updateToast);
    });
}

import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Auth from '../../auth/auth';
import InfoMessage from '../info-message/InfoMessage';
import * as actions from '../user/actions';
import {
  getUser,
  getUserError,
  getUserTenantsError,
  getUserTenantsFetched,
  getUserHasActiveTenant,
} from '../user/selectors';
import Spinner from '../common/spinner';

const Reroute = props => {
  const auth = new Auth();
  const userTenants = useSelector(x => x.userTenants.userTenants);
  const userTenantsFetched = useSelector(x => x.userTenants.fetched);
  let firstTenant = null;
  if(!!userTenants && userTenants.length > 0) {
    if(userTenants.length > 1) {
      firstTenant = userTenants.find(x => x.name.toLowerCase().indexOf('training') === -1); //if there is more than one tenant, pick the first one that doesnt have the word 'training' in the name
    } else {
      firstTenant = userTenants[0];
    }
  }

  const isAdmin = auth.isAdmin();
  if (isAdmin) {
    return <Redirect to="/app/admin/tenants" />;
  }

  if (firstTenant) {
    return <Redirect to={`/app/tenants/${firstTenant.id}/projects`} />;
  }

  if(!userTenantsFetched)
    return <Spinner />

  return <InfoMessage title='No enabled tenant' detail='Please contact GovInvest support to be added to a Labor Costing tenant.' />
};

const mapStateToProps = state => ({
  user: getUser(state),
  userNotFound: getUserError(state),
  userTenantNotFound: getUserTenantsError(state),
  userTenantsFetched: getUserTenantsFetched(state),
  userHasActiveTenant: getUserHasActiveTenant(state),
});

export default connect(mapStateToProps, actions)(Reroute);

import React from 'react';
import { colors, chartColors } from '../../style/colors';
import styled from 'styled-components';
import { formatDollarValue } from '../../shared/chartUtility';

const StyledChartCap = styled.div`
  display: grid;
  grid-row-gap: 20px;
  align-items: center;
  margin: 0 60px 15px 60px;
`;

const StyledFirstRow = styled.div`
  color: ${props => (props.printView ? '#555555' : '')};
  font-size: ${props => (props.printView ? '14px' : '')};
  justify-self: center;
  text-align: center;
`;

const StyledSecondRow = styled.div`
  position: relative;
  display: ${props => (props.minifyLegend ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.printView ? 'auto auto' : 'repeat(5, auto)'};
  grid-column-gap: 10px;
  grid-row-gap: 2.5px;
  align-items: center;
  justify-content: space-around;
`;

const StyledLegend = styled.div`
  display: ${props => (props.minifyLegend ? 'flex' : 'grid')};
  grid-auto-flow: ${props => (props.minifyLegend ? 'column' : 'row')};
  grid-column-gap: 5px;
  font-size: ${props => (props.minifyLegend ? '14px' : '16px')};
  align-items: center;
  font-weight: 500;
`;

const LegendName = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.minifyLegend ? 'flex-start' : 'center')};
  font-size: ${props => (props.printView ? '12px' : '')};
  font-weight: ${props => (props.printView ? '400' : '500')};
  white-space: nowrap;
`;

const LegendValueWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${props => (props.printView ? '20px' : '')};
  font-weight: 500;
  white-space: nowrap;
`;

const LegendValue = styled.div`
  height: ${props => (props.printView ? '' : '20px')};
  font-size: ${props => (props.printView ? '12px' : '')};
  font-weight: ${props => (props.printView ? '400' : '500')};
`;

const CircleReplacementDash = styled.div`
  margin: 0 5px;
`;

class ChartCap extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.chartProperties.calculateOnUpdate) {
      return true;
    } else {
      if (nextProps.chartProperties.lastUpdateType === 'calculation') {
        return true;
      } else {
        return false;
      }
    }
  }
  render() {
    const {
      chartData,
      hideValues,
      chartProperties,
      selectedYearIndex,
    } = this.props;
    const {
      chartType,
      description,
      hideBaseline,
      chartCapSeries,
      proposalIsChanged,
      currentProposalImpactName,
    } = chartProperties;
    const chartColorsToUse = [];
    if (!hideBaseline) {
      chartColorsToUse.push(colors.red);
    }
    for(var i = 1; i < chartColors.length; i++) {
      chartColorsToUse.push(chartColors[i]);
    }

    let showValues = true;
    if (hideValues) {
      showValues = false;
    }

    let showLegend = true;
    if (chartType === 'table') {
      showLegend = false;
    }
    const minifyLegend = chartCapSeries.length >= 6;

    const determineColor = (proposalName, seriesIndex, seriesArray) => {
      // TODO: refactor for readability
      const showCurrentProposalImpact =
        chartData[0][currentProposalImpactName] !== undefined;
      if (seriesArray.length < 6) {
        if (proposalIsChanged && showCurrentProposalImpact && seriesIndex > 0) {
          if (proposalName === currentProposalImpactName) {
            return colors.lightblue;
          } else {
            return chartColorsToUse[seriesIndex - 1];
          }
        } else {
          return chartColorsToUse[seriesIndex];
        }
      } else {
        if (proposalIsChanged && showCurrentProposalImpact) {
          if (seriesIndex > 0) {
            if (proposalName === currentProposalImpactName) {
              return colors.lightblue;
            }
            return chartColorsToUse[seriesIndex - 1];
          } else {
            return chartColorsToUse[seriesIndex];
          }
        } else {
          return chartColorsToUse[seriesIndex];
        }
      }
    };
    return (
      <StyledChartCap>
        <StyledFirstRow printView={this.props.printView}>
          {description}
        </StyledFirstRow>
        {showLegend && (
          <StyledSecondRow
            minifyLegend={minifyLegend}
            printView={this.props.printView}
          >
            {chartCapSeries.map((proposalName, seriesIndex, seriesArray) => (
              <StyledLegend
                key={seriesIndex}
                style={{
                  color: determineColor(proposalName, seriesIndex, seriesArray),
                }}
                minifyLegend={minifyLegend}
              >
                <LegendName
                  minifyLegend={minifyLegend}
                  printView={this.props.printView}
                >
                  {proposalName}
                </LegendName>
                {minifyLegend && (
                  <CircleReplacementDash>-</CircleReplacementDash>
                )}
                {showValues && (
                  <LegendValueWrapper printView={this.props.printView}>
                    {!minifyLegend && (
                      <div
                        className="sm-circle"
                        style={{
                          borderStyle: `${
                            proposalName === currentProposalImpactName &&
                            chartType === 'line'
                              ? 'dashed'
                              : 'solid'
                          }`,
                        }}
                      />
                    )}
                    <LegendValue printView={this.props.printView}>
                      {chartData[selectedYearIndex] &&
                      chartData[selectedYearIndex][proposalName]
                        ? formatDollarValue(
                            chartData[selectedYearIndex][proposalName]
                          )
                        : '$0'}
                    </LegendValue>
                  </LegendValueWrapper>
                )}
              </StyledLegend>
            ))}
          </StyledSecondRow>
        )}
      </StyledChartCap>
    );
  }
}

export default ChartCap;

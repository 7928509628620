import _ from 'lodash';

const initialState = {
  projects: null,
};

export const projects = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'FETCH_PROJECTS_SUCCESS': {
      const newState = _.cloneDeep(state);
      newState.projects = _.cloneDeep(payload.projects);
      newState.tenantId = payload.tenantId;
      return newState;
    }
    default: {
      return state;
    }
  }
}
export const UPLOAD_ACCOUNTING_MATRIX_START = 'UPLOAD_ACCOUNTING_MATRIX_START';
export const UPLOAD_ACCOUNTING_MATRIX_SUCCESS =
  'UPLOAD_ACCOUNTING_MATRIX_SUCCESS';
export const UPLOAD_ACCOUNTING_MATRIX_FAILURE =
  'UPLOAD_ACCOUNTING_MATRIX_FAILURE';
export const UPDATE_ACCOUNTING_MATRIX_UPLOAD_PROGRESS =
  'UPDATE_ACCOUNTING_MATRIX_UPLOAD_PROGRESS';
export const CLEAR_ACCOUNTING_MATRIX_UPLOAD_PROGRESS =
  'CLEAR_ACCOUNTING_MATRIX_UPLOAD_PROGRESS';
export const UPDATE_ACCOUNTING_MATRIX_STATUS_START =
  'UPDATE_ACCOUNTING_MATRIX_STATUS_START';
export const UPDATE_ACCOUNTING_MATRIX_STATUS_SUCCESS =
  'UPDATE_ACCOUNTING_MATRIX_STATUS_SUCCESS';
export const UPDATE_ACCOUNTING_MATRIX_STATUS_FAILURE =
  'UPDATE_ACCOUNTING_MATRIX_STATUS_FAILURE';
export const GET_ACCOUNTING_MATRIX_BY_ID_START =
  'GET_ACCOUNTING_MATRIX_BY_ID_START';
export const GET_ACCOUNTING_MATRIX_BY_ID_SUCCESS =
  'GET_ACCOUNTING_MATRIX_BY_ID_SUCCESS';
export const GET_ACCOUNTING_MATRIX_BY_ID_FAILURE =
  'GET_ACCOUNTING_MATRIX_BY_ID_FAILURE';
export const GET_ACCOUNTING_MATRIX_BY_ID_INVALID =
  'GET_ACCOUNTING_MATRIX_BY_ID_INVALID';
export const GET_ACCOUNTING_MATRIX_START = 'GET_ACCOUNTING_MATRIX_START';
export const GET_ACCOUNTING_MATRIX_SUCCESS = 'GET_ACCOUNTING_MATRIX_SUCCESS';
export const GET_ACCOUNTING_MATRIX_FAILURE = 'GET_ACCOUNTING_MATRIX_FAILURE';
export const GET_ACCOUNTING_MATRIX_SAS_TOKEN_START =
  'GET_ACCOUNTING_MATRIX_SAS_TOKEN_START';
export const GET_ACCOUNTING_MATRIX_SAS_TOKEN_SUCCESS =
  'GET_ACCOUNTING_MATRIX_SAS_TOKEN_SUCCESS';
export const GET_ACCOUNTING_MATRIX_SAS_TOKEN_FAILURE =
  'GET_ACCOUNTING_MATRIX_SAS_TOKEN_FAILURE';
export const UPDATE_ACTIVE_ACCOUNTING_MATRIX_START =
  'UPDATE_ACTIVE_ACCOUNTING_MATRIX_START';
export const UPDATE_ACTIVE_ACCOUNTING_MATRIX_SUCCESS =
  'UPDATE_ACTIVE_ACCOUNTING_MATRIX_SUCCESS';
export const UPDATE_ACTIVE_ACCOUNTING_MATRIX_FAILURE =
  'UPDATE_ACTIVE_ACCOUNTING_MATRIX_FAILURE';
export const DELETE_ACCOUNTING_MATRIX_START = 'DELETE_ACCOUNTING_MATRIX_START';
export const DELETE_ACCOUNTING_MATRIX_SUCCESS =
  'DELETE_ACCOUNTING_MATRIX_SUCCESS';
export const DELETE_ACCOUNTING_MATRIX_FAILURE =
  'DELETE_ACCOUNTING_MATRIX_FAILURE';
export const GET_ACCOUNTING_MATRIX_DATA_DICTIONARY_START =
  'GET_ACCOUNTING_MATRIX_DATA_DICTIONARY_START';
export const GET_ACCOUNTING_MATRIX_DATA_DICTIONARY_SUCCESS =
  'GET_ACCOUNTING_MATRIX_DATA_DICTIONARY_SUCCESS';
export const GET_ACCOUNTING_MATRIX_DATA_DICTIONARY_FAILURE =
  'GET_ACCOUNTING_MATRIX_DATA_DICTIONARY_FAILURE';

export const UPLOAD_PARTIAL_ALLOCATION_START = 'UPLOAD_PARTIAL_ALLOCATION_START';
export const UPLOAD_PARTIAL_ALLOCATION_SUCCESS =
  'UPLOAD_PARTIAL_ALLOCATION_SUCCESS';
export const UPLOAD_PARTIAL_ALLOCATION_FAILURE =
  'UPLOAD_PARTIAL_ALLOCATION_FAILURE';
export const UPDATE_PARTIAL_ALLOCATION_UPLOAD_PROGRESS =
  'UPDATE_PARTIAL_ALLOCATION_UPLOAD_PROGRESS';
export const CLEAR_PARTIAL_ALLOCATION_UPLOAD_PROGRESS =
  'CLEAR_PARTIAL_ALLOCATION_UPLOAD_PROGRESS';
export const UPDATE_PARTIAL_ALLOCATION_STATUS_START =
  'UPDATE_PARTIAL_ALLOCATION_STATUS_START';
export const UPDATE_PARTIAL_ALLOCATION_STATUS_SUCCESS =
  'UPDATE_PARTIAL_ALLOCATION_STATUS_SUCCESS';
export const UPDATE_PARTIAL_ALLOCATION_STATUS_FAILURE =
  'UPDATE_PARTIAL_ALLOCATION_STATUS_FAILURE';
export const GET_PARTIAL_ALLOCATION_BY_ID_START =
  'GET_PARTIAL_ALLOCATION_BY_ID_START';
export const GET_PARTIAL_ALLOCATION_BY_ID_SUCCESS =
  'GET_PARTIAL_ALLOCATION_BY_ID_SUCCESS';
export const GET_PARTIAL_ALLOCATION_BY_ID_FAILURE =
  'GET_PARTIAL_ALLOCATION_BY_ID_FAILURE';
export const GET_PARTIAL_ALLOCATION_BY_ID_INVALID =
  'GET_PARTIAL_ALLOCATION_BY_ID_INVALID';
export const GET_PARTIAL_ALLOCATION_START = 'GET_PARTIAL_ALLOCATION_START';
export const GET_PARTIAL_ALLOCATION_SUCCESS = 'GET_PARTIAL_ALLOCATION_SUCCESS';
export const GET_PARTIAL_ALLOCATION_FAILURE = 'GET_PARTIAL_ALLOCATION_FAILURE';
export const GET_PARTIAL_ALLOCATION_SAS_TOKEN_START =
  'GET_PARTIAL_ALLOCATION_SAS_TOKEN_START';
export const GET_PARTIAL_ALLOCATION_SAS_TOKEN_SUCCESS =
  'GET_PARTIAL_ALLOCATION_SAS_TOKEN_SUCCESS';
export const GET_PARTIAL_ALLOCATION_SAS_TOKEN_FAILURE =
  'GET_PARTIAL_ALLOCATION_SAS_TOKEN_FAILURE';
export const UPDATE_ACTIVE_PARTIAL_ALLOCATION_START =
  'UPDATE_ACTIVE_PARTIAL_ALLOCATION_START';
export const UPDATE_ACTIVE_PARTIAL_ALLOCATION_SUCCESS =
  'UPDATE_ACTIVE_PARTIAL_ALLOCATION_SUCCESS';
export const UPDATE_ACTIVE_PARTIAL_ALLOCATION_FAILURE =
  'UPDATE_ACTIVE_PARTIAL_ALLOCATION_FAILURE';
export const DELETE_PARTIAL_ALLOCATION_START = 'DELETE_PARTIAL_ALLOCATION_START';
export const DELETE_PARTIAL_ALLOCATION_SUCCESS =
  'DELETE_PARTIAL_ALLOCATION_SUCCESS';
export const DELETE_PARTIAL_ALLOCATION_FAILURE =
  'DELETE_PARTIAL_ALLOCATION_FAILURE';
export const GET_PARTIAL_ALLOCATION_DATA_DICTIONARY_START =
  'GET_PARTIAL_ALLOCATION_DATA_DICTIONARY_START';
export const GET_PARTIAL_ALLOCATION_DATA_DICTIONARY_SUCCESS =
  'GET_PARTIAL_ALLOCATION_DATA_DICTIONARY_SUCCESS';
export const GET_PARTIAL_ALLOCATION_DATA_DICTIONARY_FAILURE =
  'GET_PARTIAL_ALLOCATION_DATA_DICTIONARY_FAILURE';

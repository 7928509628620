export const UPLOAD_AGG_COST_TABLE_START = 'UPLOAD_AGG_COST_TABLE_START';
export const UPLOAD_AGG_COST_TABLE_SUCCESS =
  'UPLOAD_AGG_COST_TABLE_SUCCESS';
export const UPLOAD_AGG_COST_TABLE_FAILURE =
  'UPLOAD_AGG_COST_TABLE_FAILURE';
export const UPDATE_AGG_COST_TABLE_UPLOAD_PROGRESS =
  'UPDATE_AGG_COST_TABLE_UPLOAD_PROGRESS';
export const CLEAR_AGG_COST_TABLE_UPLOAD_PROGRESS =
  'CLEAR_AGG_COST_TABLE_UPLOAD_PROGRESS';
export const UPDATE_AGG_COST_TABLE_STATUS_START =
  'UPDATE_AGG_COST_TABLE_STATUS_START';
export const UPDATE_AGG_COST_TABLE_STATUS_SUCCESS =
  'UPDATE_AGG_COST_TABLE_STATUS_SUCCESS';
export const UPDATE_AGG_COST_TABLE_STATUS_FAILURE =
  'UPDATE_AGG_COST_TABLE_STATUS_FAILURE';
export const GET_AGG_COST_TABLE_BY_ID_START =
  'GET_AGG_COST_TABLE_BY_ID_START';
export const GET_AGG_COST_TABLE_BY_ID_SUCCESS =
  'GET_AGG_COST_TABLE_BY_ID_SUCCESS';
export const GET_AGG_COST_TABLE_BY_ID_FAILURE =
  'GET_AGG_COST_TABLE_BY_ID_FAILURE';
export const GET_AGG_COST_TABLE_BY_ID_INVALID =
  'GET_AGG_COST_TABLE_BY_ID_INVALID';
export const GET_AGG_COST_TABLE_START = 'GET_AGG_COST_TABLE_START';
export const GET_AGG_COST_TABLE_SUCCESS = 'GET_AGG_COST_TABLE_SUCCESS';
export const GET_AGG_COST_TABLE_FAILURE = 'GET_AGG_COST_TABLE_FAILURE';
export const GET_AGG_COST_TABLE_SAS_TOKEN_START =
  'GET_AGG_COST_TABLE_SAS_TOKEN_START';
export const GET_AGG_COST_TABLE_SAS_TOKEN_SUCCESS =
  'GET_AGG_COST_TABLE_SAS_TOKEN_SUCCESS';
export const GET_AGG_COST_TABLE_SAS_TOKEN_FAILURE =
  'GET_AGG_COST_TABLE_SAS_TOKEN_FAILURE';
export const UPDATE_ACTIVE_AGG_COST_TABLE_START =
  'UPDATE_ACTIVE_AGG_COST_TABLE_START';
export const UPDATE_ACTIVE_AGG_COST_TABLE_SUCCESS =
  'UPDATE_ACTIVE_AGG_COST_TABLE_SUCCESS';
export const UPDATE_ACTIVE_AGG_COST_TABLE_FAILURE =
  'UPDATE_ACTIVE_AGG_COST_TABLE_FAILURE';
export const DELETE_AGG_COST_TABLE_START = 'DELETE_AGG_COST_TABLE_START';
export const DELETE_AGG_COST_TABLE_SUCCESS =
  'DELETE_AGG_COST_TABLE_SUCCESS';
export const DELETE_AGG_COST_TABLE_FAILURE =
  'DELETE_AGG_COST_TABLE_FAILURE';

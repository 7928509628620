import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors } from '../../../style/colors';

const styles = theme => ({
  progress: {
    color: colors.themePrimary,
    margin: theme.spacing(2),
  },
});

function Spinner(props) {
  const { classes } = props;
  return (
    <CircularProgress
      className={classes.progress}
      size={props.size}
      thickness={props.thickness}
      style={{ color: `${props.color}` }}
    />
  );
}

Spinner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Spinner);

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free-solid';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyPopper from '../common/my-popper';
import TenantImage from '../tenantImage/TenantImage';

const UserMenuContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${props => (props.tenantNameExists ? '15px' : '')};
  align-items: center;
  padding: 10px 0 10px 0;
`;

const MenuListStyled = styled(MenuList)`
  color: var(--color-theme-primary);
`;

const MenuItemStyled = styled(MenuItem)`
  &:hover {
    color: var(--color-theme-primary-d);
  }
`;

const UserMenu = props => {
  const { userName, tenantName } = props;
  const tenantNameExists = !!tenantName;
  const profile = useSelector(x => x.profile);
  const databaseUser = profile.userDatabaseConnection;
  const enableLogoutSso = !profile.ssoLogoutDisabled;
  const currentTenant = useSelector(x => x.tenant.tenant);

  let tenantIdForImage = profile.tenantId;
  let onTenantsAdminPage = window.location.pathname.indexOf('admin/tenants') !== -1;
  if(!!currentTenant && !!currentTenant.id && !onTenantsAdminPage)
    tenantIdForImage = currentTenant.id;

  return (
    <MyPopper
      closeOnClick={true}
      titleComponent={
        <UserMenuContainer tenantNameExists={tenantNameExists}>
          <div className="user-city">{tenantName}</div>
          <div className="user-name">
            {userName}
            <FontAwesomeIcon icon="caret-down" />
            <div style={{marginLeft:'5px'}}>
            <TenantImage width='32px' tenantId={tenantIdForImage} cursor='pointer' />
            </div>
          </div>
        </UserMenuContainer>
      }
      poppedComponent={
        <MenuListStyled role="menu">
          <Link to="/app/my-profile">
            <MenuItem>Profile</MenuItem>
          </Link>
          { databaseUser && ( <MenuItemStyled onClick={() => props.auth.changePassword(false)}>Change Password</MenuItemStyled>) }
          { databaseUser && ( <MenuItemStyled onClick={props.auth.logout}>Logout</MenuItemStyled>) }
          { !databaseUser && ( <MenuItemStyled onClick={props.auth.switchUser}>Logout</MenuItemStyled>) }
          { (!databaseUser && enableLogoutSso) && ( <MenuItemStyled onClick={props.auth.logout}>Logout SSO</MenuItemStyled>) }
        </MenuListStyled>
      }
    />
  );
};

export default UserMenu;

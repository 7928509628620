import React from 'react';
import urlJoin from 'url-join';

function TenantImage(props) {
  let { tenantId, width, height } = props;
  if(!height) {
    height = width;
  }
  const iumBaseUrl = window.env.IUM_BASE_URL;
  const uri = urlJoin(iumBaseUrl, `api/tenant-img/${tenantId}`);
  return ( <img style={{width:width, height:height}} src={uri} alt="Tenant Logo" loading="lazy" />);
}
export default TenantImage;
import React from 'react';
import styled from 'styled-components';
import UserMenu from './user-menu';
import AdminMenu from './admin-menu';
import UserHelpMenu from './user-help-menu';
import Notifications from './notifications';
import ProductionBanner from './production-banner';
import govInvestLogoIcon from '../../assets/govinvest_logo_icon_small.png';
import govInvestLogoTitle from '../../assets/govinvest_logo_title_small.png';
import './style.css';
import HomeButton from './home-button';

const HeaderOptionsContainer = styled.div`
  grid-area: 1 / 3 / span 1 / span 1;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 15px;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  user-select: none;
`;

const HeaderLogoContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  align-items: center;
  justify-items: center;
  margin-right: 25px;
`;

const ImgStyled = styled.img`
  max-height: 40px;
`;

const LogoTitleStyled = styled(ImgStyled)`
  height: 22px;
  margin-bottom: 4px;
`;

const View = ({ auth, userName, tenantName, headerMode, screenWidth, projectName }) => {
  const { isAdmin } = auth;

  const styleTemplate =
    headerMode === 'workspace'
      ? '320px 1fr auto'
      : `0px 1fr auto`;

  const style = {
    gridTemplateColumns: styleTemplate,
    paddingLeft:'15px'
  };

  const headerTitle = () => {
    const title =
      headerMode === 'project-setup' && projectName
        ? `Labor Costing Module - ${projectName}`
        : 'Labor Costing Module';
    return <h1 className="app-header-title">{title}</h1>;
  };

  const showProductionBanner = window.env.SHOW_PRODUCTION_BANNER;

  return (
    <div className={`header-container ${headerMode ? headerMode + '-mode' : ''}`} style={style}>
      <div className="header-title">
        <HeaderLogoContainer>
          <ImgStyled src={govInvestLogoIcon} alt="govinvest logo icon" />
          <LogoTitleStyled src={govInvestLogoTitle} alt="govinvest" />
        </HeaderLogoContainer>
        {headerTitle()}
      </div>
      <HeaderOptionsContainer>
        <Notifications />
        {isAdmin() && showProductionBanner && <ProductionBanner />}
        {isAdmin() && <AdminMenu />}
        <UserHelpMenu auth={auth} tenantName={tenantName} userName={userName} />
        <HomeButton />
        <UserMenu auth={auth} tenantName={tenantName} userName={userName} />
      </HeaderOptionsContainer>
    </div>
  );
};

export default View;
export const ADD_SALARY_INCREASE_TO_PROJECT_START =
  'ADD_SALARY_INCREASE_TO_PROJECT_START';
export const ADD_SALARY_INCREASE_TO_PROJECT_SUCCESS =
  'ADD_SALARY_INCREASE_TO_PROJECT_SUCCESS';
export const ADD_SALARY_INCREASE_TO_PROJECT_FAILURE =
  'ADD_SALARY_INCREASE_TO_PROJECT_FAILURE';
export const EDIT_SALARY_INCREASE_START = 'EDIT_SALARY_INCREASE_START';
export const EDIT_SALARY_INCREASE_SUCCESS = 'EDIT_SALARY_INCREASE_SUCCESS';
export const EDIT_SALARY_INCREASE_FAILURE = 'EDIT_SALARY_INCREASE_FAILURE';
export const DELETE_SALARY_INCREASE_START = 'DELETE_SALARY_INCREASE_START';
export const DELETE_SALARY_INCREASE_SUCCESS = 'DELETE_SALARY_INCREASE_SUCCESS';
export const DELETE_SALARY_INCREASE_FAILURE = 'DELETE_SALARY_INCREASE_FAILURE';

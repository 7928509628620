export const CLEAR_TENANT = 'CLEAR_TENANT';

export const FETCH_TENANT_BY_ID_START = 'FETCH_TENANT_BY_ID_START';
export const FETCH_TENANT_BY_ID_SUCCESS = 'FETCH_TENANT_BY_ID_SUCCESS';
export const FETCH_TENANT_BY_ID_FAILURE = 'FETCH_TENANT_BY_ID_FAILURE';

export const FETCH_USERS_BY_TENANT_ID_START = 'FETCH_USERS_BY_TENANT_ID_START';
export const FETCH_USERS_BY_TENANT_ID_SUCCESS =
  'FETCH_USERS_BY_TENANT_ID_SUCCESS';
export const FETCH_USERS_BY_TENANT_ID_FAILURE =
  'FETCH_USERS_BY_TENANT_ID_FAILURE';

export const FETCH_TENANTS_START = 'FETCH_TENANTS_START';
export const FETCH_TENANTS_SUCCESS = 'FETCH_TENANTS_SUCCESS';
export const FETCH_TENANTS_FAILURE = 'FETCH_TENANTS_FAILURE';

export const ADD_USER_TO_TENANT_START = 'ADD_USER_TO_TENANT_START';
export const ADD_USER_TO_TENANT_SUCCESS = 'ADD_USER_TO_TENANT_SUCCESS';
export const ADD_USER_TO_TENANT_FAILURE = 'ADD_USER_TO_TENANT_FAILURE';

export const CHANGE_TENANT_NAME_START = 'CHANGE_TENANT_NAME_START';
export const CHANGE_TENANT_NAME_SUCCESS = 'CHANGE_TENANT_NAME_SUCCESS';
export const CHANGE_TENANT_NAME_FAILURE = 'CHANGE_TENANT_NAME_FAILURE';

export const DELETE_USER_FROM_TENANT_BY_EMAIL_SLUG_START =
  'DELETE_USER_FROM_TENANT_BY_EMAIL_SLUG_START';
export const DELETE_USER_FROM_TENANT_BY_EMAIL_SLUG_SUCCESS =
  'DELETE_USER_FROM_TENANT_BY_EMAIL_SLUG_SUCCESS';
export const DELETE_USER_FROM_TENANT_BY_EMAIL_SLUG_FAILURE =
  'DELETE_USER_FROM_TENANT_BY_EMAIL_SLUG_FAILURE';

import * as config from '../../lib/config';
import Auth from '../../auth/auth';

import {
  CLEAR_USER,
  FETCH_USER_TENANTS_SUCCESS,
  FETCH_USER_NOTIFICATIONS_SUCCESS,
} from './types';

// Actions
const clearUserProp = () => ({
  type: CLEAR_USER,
});

// Error handlers
function handleFetchErrors(response) {
  if (!response.ok) {
    console.error('Failed to retrieve tenants.');
    throw Error('Failed to retrieve tenants.');
  }
  return response;
}

// API request actions
export const setProfile = payload => ({
  type: 'SET_PROFILE',
  payload: payload,
});


export const clearUser = () => dispatch => {
  dispatch(clearUserProp());
};

export const fetchUserTenants = userId => dispatch => {
  new Auth().checkAuth();
  return fetch(`${config.baseURL()}/api/usertenants/${userId}`, {
    method: 'get',
    credentials: 'include'
  })
    .then(handleFetchErrors)
    .then(res => res.json())
    .then(json => {
      dispatch({ type: FETCH_USER_TENANTS_SUCCESS, payload: { tenants: json } });
    })
    .catch(error => {
      console.error('Error fetching user tenants', error)
    });
};

export const fetchUserNotifications = userId => dispatch => {
  new Auth().checkAuth();
  return fetch(`${config.baseURL()}/api/notifications/${userId}`, {
    method: 'get',
    credentials: 'include'
  })
    .then(handleFetchErrors)
    .then(res => res.json())
    .then(json => {
      dispatch({ type: FETCH_USER_NOTIFICATIONS_SUCCESS, payload: { notifications: json } });
    })
    .catch(error => {
      console.error('Error fetching user tenants', error)
    });
};
export function determineScale(max) {
  if (max < 950) {
    return '';
  } else if (max < 750000) {
    return 'K';
  } else if (max < 750000000) {
    return 'M';
  } else {
    return 'B';
  }
}

export function determineDivisor(scale) {
  return scale === ''
    ? 1
    : scale === 'K'
      ? 1000
      : scale === 'M'
        ? 1000000
        : 1000000000;
}

export function determineScaleAndDivisorSingleValue(value) {
  return determineScaleAndDivisor(value, value);
}

export function determineScaleAndDivisor(min, max) {
  const abs = Math.abs(min);
  const absMax = Math.max(abs, max);
  const scale = determineScale(absMax);
  const divisor = determineDivisor(scale);
  return { scale, divisor };
}

export function determineScaleAndDivisorForChartData(chartData) {
  let max = Number.MIN_SAFE_INTEGER;
  let min = Number.MAX_SAFE_INTEGER;
  chartData.forEach(point => {
    Object.keys(point).forEach(key => {
      if (key === 'year') {
        return;
      }
      const value = point[key];
      max = Math.max(value, max);
      min = Math.min(value, min);
    });
  });
  return determineScaleAndDivisor(min, max);
}

export function formatDollarValue(value) {
  if (isNaN(value)) {
    console.trace('warning: isNaN when formatting dollar number')
    return '$0';
  }
  const { scale, divisor } = determineScaleAndDivisorSingleValue(value);
  const formatted = `$${parseFloat(value / divisor).toFixed(2)} ${scale}`;
  return formatted;
}

export function formatPercentValue(value) {
  if (isNaN(value)) {
    console.log('warning: isNaN when formatting dollar number');
    return '0%';
  }
  const formatted = `${(value * 100).toFixed(1)}%`;
  return formatted;
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';
import View from './view';

class ErrorModal extends Component {
  render() {
    return (
      <View
        dismissError={this.props.dismissError}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dismissError: () => dispatch(actions.dismissError()),
});

export default connect(
  null,
  mapDispatchToProps
)(ErrorModal);

import {
  GET_SAS_TOKEN_START,
  GET_SAS_TOKEN_SUCCESS,
  GET_SAS_TOKEN_FAILURE,
  GET_HEALTHCARE_DATA_START,
  GET_HEALTHCARE_DATA_SUCCESS,
  GET_HEALTHCARE_DATA_FAILURE,
  GET_HEALTHCARE_TEMPLATE_DATA_START,
  GET_HEALTHCARE_TEMPLATE_DATA_SUCCESS,
  GET_HEALTHCARE_TEMPLATE_DATA_FAILURE,
  DELETE_HEALTHCARE_DATA_START,
  DELETE_HEALTHCARE_DATA_SUCCESS,
  DELETE_HEALTHCARE_DATA_FAILURE,
  GET_HEALTHCARE_DATA_BY_ID_START,
  GET_HEALTHCARE_DATA_BY_ID_SUCCESS,
  GET_HEALTHCARE_DATA_BY_ID_INVALID,
  GET_HEALTHCARE_DATA_BY_ID_FAILURE,
  UPLOAD_HEALTHCARE_DATA_START,
  UPLOAD_HEALTHCARE_DATA_SUCCESS,
  UPLOAD_HEALTHCARE_DATA_FAILURE,
  CLEAR_HEALTHCARE_DATA_UPLOAD_PROGRESS,
  UPDATE_HEALTHCARE_DATA_UPLOAD_PROGRESS,
  UPDATE_HEALTHCARE_DATA_STATUS_START,
  UPDATE_HEALTHCARE_DATA_STATUS_SUCCESS,
  UPDATE_HEALTHCARE_DATA_STATUS_FAILURE,
  UPDATE_ACTIVE_HEALTHCARE_DATA_START,
  UPDATE_ACTIVE_HEALTHCARE_DATA_SUCCESS,
  UPDATE_ACTIVE_HEALTHCARE_DATA_FAILURE,
} from './types';

const progressInitialState = {
  percent: 0,
  uploading: false,
  validating: false,
  validated: false,
  error: null,
  response: {},
};

export const healthcareDataUploadProgress = (
  state = progressInitialState,
  { type, payload }
) => {
  switch (type) {
    case GET_SAS_TOKEN_START: {
      return {
        ...state,
        uploading: true,
        validating: false,
      };
    }
    case GET_SAS_TOKEN_SUCCESS: {
      return { ...state };
    }
    case GET_SAS_TOKEN_FAILURE: {
      return { ...state };
    }
    case UPLOAD_HEALTHCARE_DATA_START:
      return {
        ...state,
        uploading: true,
      };
    case UPLOAD_HEALTHCARE_DATA_SUCCESS:
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case UPLOAD_HEALTHCARE_DATA_FAILURE:
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case UPDATE_HEALTHCARE_DATA_UPLOAD_PROGRESS: {
      return {
        ...state,
        percent: payload,
      };
    }
    case UPDATE_HEALTHCARE_DATA_STATUS_START: {
      return {
        ...state,
        validating: true,
        validated: false,
        error: null,
      };
    }
    case UPDATE_HEALTHCARE_DATA_STATUS_SUCCESS: {
      return {
        ...state,
      };
    }
    case UPDATE_HEALTHCARE_DATA_STATUS_FAILURE: {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
      };
    }
    case GET_HEALTHCARE_DATA_BY_ID_START: {
      return {
        ...state,
        validating: true,
        validated: false,
        error: null,
      };
    }
    case GET_HEALTHCARE_DATA_BY_ID_SUCCESS: {
      return {
        ...state,
        validating: false,
        validated: true,
        error: false,
        response: payload,
      };
    }
    case GET_HEALTHCARE_DATA_BY_ID_INVALID: {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case GET_HEALTHCARE_DATA_BY_ID_FAILURE: {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case CLEAR_HEALTHCARE_DATA_UPLOAD_PROGRESS: {
      return progressInitialState;
    }
    default:
      return state;
  }
};

const healthcareDataInitialState = {
  loading: false,
  modifying: false,
  error: null,
  healthcareDataFiles: [],
};

export const healthcareDataFiles = (state = healthcareDataInitialState, { type, payload }) => {
  switch (type) {
    case GET_HEALTHCARE_DATA_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case GET_HEALTHCARE_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: null,
        healthcareDataFiles: payload,
      };
    }
    case GET_HEALTHCARE_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: payload,
      };
    }
    case UPDATE_ACTIVE_HEALTHCARE_DATA_START: {
      return {
        ...state,
        modifying: true,
      };
    }
    case UPDATE_ACTIVE_HEALTHCARE_DATA_SUCCESS: {
      return {
        ...state,
      };
    }
    case UPDATE_ACTIVE_HEALTHCARE_DATA_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case DELETE_HEALTHCARE_DATA_START: {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case DELETE_HEALTHCARE_DATA_SUCCESS: {
      return {
        ...state,
        error: null,
      };
    }
    case DELETE_HEALTHCARE_DATA_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    default:
      return state;
  }
};

const healthcareDataTemplateInitialState = {
  loading: false,
  modifying: false,
  error: null,
  healthcareDataTemplate: [],
};

export const healthcareDataTemplate = (state = healthcareDataTemplateInitialState, { type, payload }) => {
  switch (type) {
    case GET_HEALTHCARE_TEMPLATE_DATA_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case GET_HEALTHCARE_TEMPLATE_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: null,
        healthcareDataTemplate: payload,
      };
    }
    case GET_HEALTHCARE_TEMPLATE_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: payload,
      };
    }
    default:
      return state;
  }
}
import {
  CLEAR_COMPARED_PROPOSALS,
  ADD_PROPOSAL_TO_COMPARISON,
  REMOVE_PROPOSAL_FROM_COMPARISON,
} from './types';

const comparedProposalsInitialState = [];

export const comparedProposals = (
  state = comparedProposalsInitialState,
  { type, payload }
) => {
  switch (type) {
    case ADD_PROPOSAL_TO_COMPARISON: {
      return [...state, payload];
    }
    case REMOVE_PROPOSAL_FROM_COMPARISON: {
      return payload;
    }
    case CLEAR_COMPARED_PROPOSALS: {
      return comparedProposalsInitialState;
    }
    default:
      return state;
  }
};

import React from 'react';
import styled from 'styled-components';
import HomeIcon from '@material-ui/icons/HomeOutlined';

function HomeButton() {
  const homeUrl = window.env.HOME_URL;
  return (
    <HomeButtonContainer>
      <a href={homeUrl} title="Home">
        <HomeIconStyled />
      </a>
    </HomeButtonContainer>
  );
}

export default HomeButton;

const HomeButtonContainer = styled.div`
`;

const HomeIconStyled = styled(HomeIcon)`
  color: var(--color-theme-primary);
  cursor: pointer;
  font-size: 26px;
  margin-top: 5px;
  &:hover {
    color: var(--color-theme-primary-d);
  }
`;
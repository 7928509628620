import React from 'react';
import { connect } from 'react-redux';
import * as viewActions from '../../../actions/viewActions.js';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import styled, { css } from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  width: max-content;
`;

const sharedIconStyle = css`
  color: var(--color-grey-d);
  font-size: 30px !important;
  transition: all 0.05s linear;
  &:hover {
    color: var(--color-theme-primary);
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const StyledFullscreen = styled(Fullscreen)`
  ${sharedIconStyle};
`;

const StyledFullscreenExit = styled(FullscreenExit)`
  ${sharedIconStyle};
`;

const ExpandButton = props => {
  const toolTip = props.topChartOpen ? "Expand" : "Collapse";
  return (
    <IconWrapper onClick={() => props.toggleTopChartCollapse()} title={toolTip}>
      {props.topChartOpen ? <StyledFullscreen /> : <StyledFullscreenExit />}
    </IconWrapper>
  );
};

const mapStateToProps = state => ({
  topChartOpen: state.UI.topChartOpen,
});

const mapDispatchToProps = dispatch => ({
  toggleTopChartCollapse: () => dispatch(viewActions.toggleTopChartCollapse()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpandButton);

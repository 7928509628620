import React from 'react';
import styled from 'styled-components';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import GetAppIcon from '@material-ui/icons/GetApp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { downloadUserGuide, downloadReleaseNotes, downloadEligibilityTips } from './user-help-downloader';
import MyPopper from '../common/my-popper';

const MenuItemContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  align-items: center;
  justify-content: center;
  color: var(--color-theme-primary);
  &:hover {
    color: var(--color-theme-primary-d);
  }
`;

const HelpOutlineIconStyled = styled(HelpOutlineIcon)`
  color: var(--color-theme-primary);
  margin-top: 6px;
  cursor: pointer;
  &:hover {
    color: var(--color-theme-primary-d);
  }
`;

const UserHelpMenu = () => {
  return (
    <MyPopper
      closeOnClick={true}
      titleComponent={<HelpOutlineIconStyled />}
      poppedComponent={
        <MenuList role="menu">
          <MenuItem
            onClick={() => {
              downloadUserGuide();
            }}
          >
            <MenuItemContainer>
              <GetAppIcon fontSize="small" />
              <div>User's Guide</div>
            </MenuItemContainer>
          </MenuItem>
          <MenuItem
            onClick={() => {
              downloadReleaseNotes();
            }}
          >
            <MenuItemContainer>
              <GetAppIcon fontSize="small" />
              <div>Release Notes</div>
            </MenuItemContainer>
          </MenuItem>
          <MenuItem
            onClick={() => {
              downloadEligibilityTips();
            }}
          >
            <MenuItemContainer>
              <GetAppIcon fontSize="small" />
              <div>Eligibility Formula Tips</div>
            </MenuItemContainer>
          </MenuItem>
        </MenuList>
      }
    />
  );
};

export default UserHelpMenu;

import React, { useState } from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const Notification = props => {
  const [notificationExpanded, setNotificationExpanded] = useState(false);
  const { notification, dismissNotification } = props;
  const notificationIsNew = notification.status === 'new';

  return (
    <CardStyled key={notification.id} $useBottomMargin={props.useBottomMargin}>
      <CardTitleRow>
        <NotificationAvatarIcon $notificationIsNew={notificationIsNew} />
        <CardTitle>{notification.title}</CardTitle>
      </CardTitleRow>

      <CardBody $expanded={notificationExpanded}>
        {notification.body}
        {notification.links.length > 0 && (
          <CardLinks $expanded={notificationExpanded}>
            {notification.links.map(link => (
              <LinkStyled
                href={link.url}
                rel="noreferrer"
                target="_blank"
                key={`notification-link-${notification.id}`}
              >
                {link.title}
              </LinkStyled>
            ))}
          </CardLinks>
        )}
      </CardBody>

      <CardFooter>
        <ReadMoreLessButton onClick={() => setNotificationExpanded(!notificationExpanded)}>
          {notificationExpanded ? 'Read less' : 'Read more'}
        </ReadMoreLessButton>
        <DismissButton onClick={dismissNotification}>Dismiss</DismissButton>
      </CardFooter>
    </CardStyled>
  );
};

export default Notification;

const CardStyled = styled(Card)`
  margin-bottom: ${props => (props.$useBottomMargin ? '5px' : '0')};
`;

const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  font-size: 12px;
  padding: 3px 7px 2.5px 7px;
  border-radius: 2.5px;
  cursor: pointer;
  transition: 0.1s ease;
  user-select: none;
`;

const DismissButton = styled(CardButton)`
  color: black;
  background: var(--color-lightgrey);
  &:hover {
    color: white;
    background: var(--color-grey);
  }
`;

const ReadMoreLessButton = styled(CardButton)`
  color: white;
  background: var(--color-theme-primary);
  &:hover {
    background: var(--color-theme-primary-d);
  }
`;

const CardFooter = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto auto;
  grid-column-gap: 10px;
  align-items: center;
  margin: 0px 5px 5px 30px;
`;

const NotificationAvatarIcon = styled(InfoOutlinedIcon)`
  color: ${props =>
    props.$notificationIsNew ? 'var(--color-theme-primary)' : 'var(--color-grey)'};
`;

const CardTitleRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 15px;
  align-items: center;
  justify-content: flex-start;
  margin: 0 10px;
`;

const CardTitle = styled.h1`
  font-size: 16px;
  color: black;
  font-weight: 400;
`;

const CardBody = styled.div`
  height: auto;
  font-size: 14px;
  padding: 0 20px;
  margin: 0 0 5px 0;
  white-space: ${props => (props.$expanded ? 'normal' : 'nowrap')};
  overflow: ${props => (props.$expanded ? '' : 'hidden')};
  text-overflow: ellipsis;
  transition: 0.15s ease;
`;

const CardLinks = styled.div`
  display: ${props => (props.$expanded ? 'grid' : 'none')};
  margin: 5px 0 0 0px;
`;

const LinkStyled = styled.a`
  width: max-content;
`;

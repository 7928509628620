export const GET_SAS_TOKEN_START = 'GET_SAS_TOKEN_START';
export const GET_SAS_TOKEN_SUCCESS = 'GET_SAS_TOKEN_SUCCESS';
export const GET_SAS_TOKEN_FAILURE = 'GET_SAS_TOKEN_FAILURE';
export const GET_CENSUS_START = 'GET_CENSUS_START';
export const GET_CENSUS_SUCCESS = 'GET_CENSUS_SUCCESS';
export const GET_CENSUS_FAILURE = 'GET_CENSUS_FAILURE';
export const GET_CENSUS_DATA_DICTIONARY_START =
  'GET_CENSUS_DATA_DICTIONARY_START';
export const GET_CENSUS_DATA_DICTIONARY_SUCCESS =
  'GET_CENSUS_DATA_DICTIONARY_SUCCESS';
export const GET_CENSUS_DATA_DICTIONARY_FAILURE =
  'GET_CENSUS_DATA_DICTIONARY_FAILURE';
export const DELETE_CENSUS_START = 'DELETE_CENSUS_START';
export const DELETE_CENSUS_SUCCESS = 'DELETE_CENSUS_SUCCESS';
export const DELETE_CENSUS_FAILURE = 'DELETE_CENSUS_FAILURE';
export const GET_CENSUS_BY_ID_START = 'GET_CENSUS_BY_ID_START';
export const GET_CENSUS_BY_ID_SUCCESS = 'GET_CENSUS_BY_ID_SUCCESS';
export const GET_CENSUS_BY_ID_INVALID = 'GET_CENSUS_BY_ID_INVALID';
export const GET_CENSUS_BY_ID_FAILURE = 'GET_CENSUS_BY_ID_FAILURE';
export const UPLOAD_CENSUS_START = 'UPLOAD_CENSUS_START';
export const UPLOAD_CENSUS_SUCCESS = 'UPLOAD_CENSUS_SUCCESS';
export const UPLOAD_CENSUS_FAILURE = 'UPLOAD_CENSUS_FAILURE';
export const UPDATE_CENSUS_UPLOAD_PROGRESS = 'UPDATE_CENSUS_UPLOAD_PROGRESS';
export const UPDATE_CENSUS_STATUS_START = 'UPDATE_CENSUS_STATUS_START';
export const UPDATE_CENSUS_STATUS_SUCCESS = 'UPDATE_CENSUS_STATUS_SUCCESS';
export const UPDATE_CENSUS_STATUS_FAILURE = 'UPDATE_CENSUS_STATUS_FAILURE';
export const UPDATE_ACTIVE_CENSUS_START = 'UPDATE_ACTIVE_CENSUS_START';
export const UPDATE_ACTIVE_CENSUS_SUCCESS = 'UPDATE_ACTIVE_CENSUS_SUCCESS';
export const UPDATE_ACTIVE_CENSUS_FAILURE = 'UPDATE_ACTIVE_CENSUS_FAILURE';
export const CLEAR_CENSUS_UPLOAD_PROGRESS = ' CLEAR_CENSUS_UPLOAD_PROGRESS';

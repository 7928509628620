import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

const ACTIVE = '(Baseline)';

const ContextContainer = styled.div`
  font-size: 11px;
  color: #555555;
  font-family: Roboto;
  margin: 20px 0 0 5px;
`;

const ContextRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  align-items: flex-start;
  justify-content: flex-start;
`;

const RowTitle = styled.div`
  font-weight: 600;
`;

const DemographicFilter = styled(ContextRow)`
  margin-left: 10px;
`;

const PrintableContext = props => {
  const {
    workspace,
    currentProposalName,
    calculationType,
    selectedCensusFileId,
    selectedPooledCensusFileId,
    selectedSalaryScheduleId,
    selectedHealthcareId,
    selectedPayGradesId,
    selectedPartialAllocationsId,
    demographics,
  } = props;
  const useActiveCensus = selectedCensusFileId === ACTIVE;
  const useActivePooledCensus = selectedPooledCensusFileId === ACTIVE;
  const useActiveSalarySchedule = selectedSalaryScheduleId === ACTIVE;
  const useActiveHealthcare = selectedHealthcareId === ACTIVE;
  const useActivePayGrades = selectedPayGradesId === ACTIVE;
  const useActivePartialAllocations = selectedPartialAllocationsId === ACTIVE;

  const renderCensus = () => {
    let censusName;
    if (useActiveCensus) {
      censusName = workspace.censusFiles.find(
        censusFile => censusFile.censusId === workspace.activeCensusId
      ).name;
    } else {
      censusName = workspace.censusFiles.find(
        censusFile => censusFile.censusId === selectedCensusFileId
      ).name;
    }

    if (censusName) {
      return (
        <ContextRow>
          <RowTitle>Census:</RowTitle>
          <div>{censusName}</div>
        </ContextRow>
      );
    }
  };

  const renderPooledCensus = () => {
    let censusName;
    if (useActivePooledCensus) {
      censusName = workspace.pooledCensusFiles.find(
        censusFile => censusFile.censusId === workspace.activePooledCensusId
      )?.name;
    } else {
      censusName = workspace.pooledCensusFiles.find(
        censusFile => censusFile.censusId === selectedPooledCensusFileId
      )?.name;
    }

    if (censusName) {
      return (
        <ContextRow>
          <RowTitle>Pooled Census:</RowTitle>
          <div>{censusName}</div>
        </ContextRow>
      );
    }
  };

  const renderHealthcare = () => {
    let healthcareName;
    if (useActiveHealthcare) {
      healthcareName = workspace.healthcareFiles.find(
        healthcare => healthcare.fileId === workspace.activeHealthcareId
      )?.name;
    } else {
      healthcareName = workspace.healthcareFiles.find(
        healthcare => healthcare.fileId === selectedHealthcareId
      )?.name;
    }

    if (healthcareName) {
      return (
        <ContextRow>
          <RowTitle>Healthcare Data:</RowTitle>
          <div>{healthcareName}</div>
        </ContextRow>
      );
    }
  };

  const renderSalarySchedule = () => {
    if (calculationType === 'SalarySchedule' || calculationType === 'PerEmployee') {
      let salaryScheduleName;
      if (useActiveSalarySchedule) {
        salaryScheduleName = workspace.salarySchedules.find(
          salarySchedule => salarySchedule.salaryScheduleId === workspace.activeSalaryScheduleId
        )?.name;
      } else {
        salaryScheduleName = workspace.salarySchedules.find(
          salarySchedule => salarySchedule.salaryScheduleId === selectedSalaryScheduleId
        )?.name;
      }

      if (salaryScheduleName) {
        return (
          <ContextRow>
            <RowTitle>Salary Schedule:</RowTitle>
            <div>{salaryScheduleName}</div>
          </ContextRow>
        );
      }
    }
  };

  const renderPayGrades = () => {
    if (calculationType === 'PayGrades' || calculationType === 'PerEmployee') {
      let payGradesName;
      if (useActivePayGrades) {
        payGradesName = workspace.payGrades.find(
          pg => pg.payGradesId === workspace.activePayGradesId
        )?.name;
      } else {
        payGradesName = workspace.payGrades.find(
          pg => pg.payGradesId === selectedPayGradesId
        )?.name;
      }
      if (payGradesName) {
        return (
          <ContextRow>
            <RowTitle>Pay Grades:</RowTitle>
            <div>{payGradesName}</div>
          </ContextRow>
        );
      }
    }
  };

  const renderPartialAllocations = () => {
    let partialAllocationsName;
    if (useActivePartialAllocations) {
      partialAllocationsName = workspace.partialAllocations.find(
        p => p.employeePartialAllocationsId === workspace.activePartialAllocationsId
      )?.name;
    } else {
      partialAllocationsName = workspace.partialAllocations.find(
        p => p.employeePartialAllocationsId === selectedPartialAllocationsId
      )?.name;
    }
    if (partialAllocationsName) {
      return (
        <ContextRow>
          <RowTitle>Partial Allocations:</RowTitle>
          <div>{partialAllocationsName}</div>
        </ContextRow>
      );
    }
  };

  const renderDemographicFilters = () => {
    const { appliedDemographicFilters } = workspace;

    if (appliedDemographicFilters && Object.keys(appliedDemographicFilters).length > 0) {
      let filters = [];
      for (const [i, appliedFilterColumn] of Object.keys(appliedDemographicFilters).entries()) {
        const demographicName = demographics.find(
          demographic => demographic.column === appliedFilterColumn
        ).name;
        filters.push(
          <DemographicFilter key={`${appliedDemographicFilters[appliedFilterColumn]}-${i}`}>
            <RowTitle>{demographicName}:</RowTitle>
            <div>{appliedDemographicFilters[appliedFilterColumn].join(', ')}</div>
          </DemographicFilter>
        );
      }
      return (
        <div>
          <RowTitle>Demographic Filters:</RowTitle>
          {filters}
        </div>
      );
    }
  };

  const getTime = () => {
    const now = dayjs();
    const hours = now.format('h:mm a');
    const date = now.format('MMM D, YYYY');
    return {
      hours,
      date,
    };
  };
  const time = getTime();

  return (
    <ContextContainer>
      <ContextRow>
        <RowTitle>Proposal:</RowTitle>
        <div>{currentProposalName}</div>
      </ContextRow>
      <ContextRow>
        <RowTitle>Time</RowTitle>
        <div>
          {time.hours} {time.date}
        </div>
      </ContextRow>
      {renderCensus()}
      {renderPooledCensus()}
      {renderSalarySchedule()}
      {renderHealthcare()}
      {renderPayGrades()}
      {renderPartialAllocations()}
      {renderDemographicFilters()}
    </ContextContainer>
  );
};

export default PrintableContext;

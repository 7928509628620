export const GET_SAS_TOKEN_START = 'GET_SAS_TOKEN_START';
export const GET_SAS_TOKEN_SUCCESS = 'GET_SAS_TOKEN_SUCCESS';
export const GET_SAS_TOKEN_FAILURE = 'GET_SAS_TOKEN_FAILURE';
export const GET_HEALTHCARE_DATA_START = 'GET_HEALTHCARE_DATA_START';
export const GET_HEALTHCARE_DATA_SUCCESS = 'GET_HEALTHCARE_DATA_SUCCESS';
export const GET_HEALTHCARE_DATA_FAILURE = 'GET_HEALTHCARE_DATA_FAILURE';
export const GET_HEALTHCARE_TEMPLATE_DATA_START = 'GET_HEALTHCARE_TEMPLATE_DATA_START';
export const GET_HEALTHCARE_TEMPLATE_DATA_SUCCESS = 'GET_HEALTHCARE_TEMPLATE_DATA_SUCCESS';
export const GET_HEALTHCARE_TEMPLATE_DATA_FAILURE = 'GET_HEALTHCARE_TEMPLATE_DATA_FAILURE';
export const DELETE_HEALTHCARE_DATA_START = 'DELETE_HEALTHCARE_DATA_START';
export const DELETE_HEALTHCARE_DATA_SUCCESS = 'DELETE_HEALTHCARE_DATA_SUCCESS';
export const DELETE_HEALTHCARE_DATA_FAILURE = 'DELETE_HEALTHCARE_DATA_FAILURE';
export const GET_HEALTHCARE_DATA_BY_ID_START = 'GET_HEALTHCARE_DATA_BY_ID_START';
export const GET_HEALTHCARE_DATA_BY_ID_SUCCESS = 'GET_HEALTHCARE_DATA_BY_ID_SUCCESS';
export const GET_HEALTHCARE_DATA_BY_ID_INVALID = 'GET_HEALTHCARE_DATA_BY_ID_INVALID';
export const GET_HEALTHCARE_DATA_BY_ID_FAILURE = 'GET_HEALTHCARE_DATA_BY_ID_FAILURE';
export const UPLOAD_HEALTHCARE_DATA_START = 'UPLOAD_HEALTHCARE_DATA_START';
export const UPLOAD_HEALTHCARE_DATA_SUCCESS = 'UPLOAD_HEALTHCARE_DATA_SUCCESS';
export const UPLOAD_HEALTHCARE_DATA_FAILURE = 'UPLOAD_HEALTHCARE_DATA_FAILURE';
export const UPDATE_HEALTHCARE_DATA_UPLOAD_PROGRESS = 'UPDATE_HEALTHCARE_DATA_UPLOAD_PROGRESS';
export const UPDATE_HEALTHCARE_DATA_STATUS_START = 'UPDATE_HEALTHCARE_DATA_STATUS_START';
export const UPDATE_HEALTHCARE_DATA_STATUS_SUCCESS = 'UPDATE_HEALTHCARE_DATA_STATUS_SUCCESS';
export const UPDATE_HEALTHCARE_DATA_STATUS_FAILURE = 'UPDATE_HEALTHCARE_DATA_STATUS_FAILURE';
export const UPDATE_ACTIVE_HEALTHCARE_DATA_START = 'UPDATE_ACTIVE_HEALTHCARE_DATA_START';
export const UPDATE_ACTIVE_HEALTHCARE_DATA_SUCCESS = 'UPDATE_ACTIVE_HEALTHCARE_DATA_SUCCESS';
export const UPDATE_ACTIVE_HEALTHCARE_DATA_FAILURE = 'UPDATE_ACTIVE_HEALTHCARE_DATA_FAILURE';
export const CLEAR_HEALTHCARE_DATA_UPLOAD_PROGRESS = ' CLEAR_HEALTHCARE_DATA_UPLOAD_PROGRESS';

export const getUser = state => state.user.user;
export const getUserFetched = state => state.user.fetched;
export const getUserError = state => state.user.error;

const userActiveTenants = state => {
  const userActiveTenants = state.userTenants.userTenants.filter(tenant => tenant.active === undefined || tenant.active); //tenant could be undefined until "reactivated"
  return userActiveTenants;
};
export const getUserTenants = state => userActiveTenants(state);
export const getUserTenantsLoading = state => state.userTenants.loading;
export const getUserTenantsFetched = state => state.userTenants.fetched;
export const getUserTenantsError = state => state.userTenants.error;
export const getUserHasActiveTenant = state => {
  return userActiveTenants(state).length >= 1;
};

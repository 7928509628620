import { DISMISS_ERROR } from '../../shared/common/constants';

import {
  CLEAR_TENANTS,
  FETCH_TENANTS_START,
  FETCH_TENANTS_SUCCESS,
  FETCH_TENANTS_FAILURE,
} from './types';

const tenantsInitialState = {
  tenants: [],
  loading: false,
  modifyingTenant: false,
  error: null,
};

export const tenants = (state = tenantsInitialState, { type, payload }) => {
  switch (type) {
    case FETCH_TENANTS_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case FETCH_TENANTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        modifyingTenant: false,
        error: null,
        tenants: payload,
      };
    }
    case FETCH_TENANTS_FAILURE: {
      return {
        ...state,
        loading: false,
        modifyingTenant: false,
        error: payload,
        tenants: [],
      };
    }
    case DISMISS_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    case CLEAR_TENANTS: {
      return tenantsInitialState;
    }
    default:
      return state;
  }
};

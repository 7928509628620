import {
  GET_SAS_TOKEN_START,
  GET_SAS_TOKEN_SUCCESS,
  GET_SAS_TOKEN_FAILURE,
  GET_CENSUS_START,
  GET_CENSUS_SUCCESS,
  GET_CENSUS_FAILURE,
  DELETE_CENSUS_START,
  DELETE_CENSUS_SUCCESS,
  DELETE_CENSUS_FAILURE,
  GET_CENSUS_BY_ID_START,
  GET_CENSUS_BY_ID_SUCCESS,
  GET_CENSUS_BY_ID_INVALID,
  GET_CENSUS_BY_ID_FAILURE,
  UPLOAD_CENSUS_START,
  UPLOAD_CENSUS_SUCCESS,
  UPLOAD_CENSUS_FAILURE,
  CLEAR_CENSUS_UPLOAD_PROGRESS,
  UPDATE_CENSUS_UPLOAD_PROGRESS,
  UPDATE_CENSUS_STATUS_START,
  UPDATE_CENSUS_STATUS_SUCCESS,
  UPDATE_CENSUS_STATUS_FAILURE,
  UPDATE_ACTIVE_CENSUS_START,
  UPDATE_ACTIVE_CENSUS_SUCCESS,
  UPDATE_ACTIVE_CENSUS_FAILURE,
  GET_CENSUS_DATA_DICTIONARY_SUCCESS,
} from './types';
const progressInitialState = {
  percent: 0,
  uploading: false,
  validating: false,
  validated: false,
  error: null,
  response: {},
};

export const censusUploadProgress = (
  state = progressInitialState,
  { type, payload }
) => {
  switch (type) {
    case GET_SAS_TOKEN_START: {
      return {
        ...state,
        uploading: true,
        validating: false,
      };
    }
    case GET_SAS_TOKEN_SUCCESS: {
      return { ...state };
    }
    case GET_SAS_TOKEN_FAILURE: {
      return { ...state };
    }
    case UPLOAD_CENSUS_START:
      return {
        ...state,
        uploading: true,
      };
    case UPLOAD_CENSUS_SUCCESS:
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case UPLOAD_CENSUS_FAILURE:
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case UPDATE_CENSUS_UPLOAD_PROGRESS: {
      return {
        ...state,
        percent: payload,
      };
    }
    case UPDATE_CENSUS_STATUS_START: {
      return {
        ...state,
        validating: true,
        validated: false,
        error: null,
      };
    }
    case UPDATE_CENSUS_STATUS_SUCCESS: {
      return {
        ...state,
      };
    }
    case UPDATE_CENSUS_STATUS_FAILURE: {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
      };
    }
    case GET_CENSUS_BY_ID_START: {
      return {
        ...state,
        validating: true,
        validated: false,
        error: null,
      };
    }
    case GET_CENSUS_BY_ID_SUCCESS: {
      return {
        ...state,
        validating: false,
        validated: true,
        error: false,
        response: payload,
      };
    }
    case GET_CENSUS_BY_ID_INVALID: {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case GET_CENSUS_BY_ID_FAILURE: {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case CLEAR_CENSUS_UPLOAD_PROGRESS: {
      return progressInitialState;
    }
    default:
      return state;
  }
};

const censusInitialState = {
  loading: false,
  modifying: false,
  error: null,
  censusFiles: [],
  pooledCensusFiles: [],
};

export const censusFiles = (state = censusInitialState, { type, payload }) => {
  switch (type) {
    case GET_CENSUS_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case GET_CENSUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: null,
        censusFiles: payload.filter(x => x.censusType !== 'pooled'),
        pooledCensusFiles: payload.filter(x => x.censusType === 'pooled'),
      };
    }
    case GET_CENSUS_FAILURE: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: payload,
      };
    }
    case UPDATE_ACTIVE_CENSUS_START: {
      return {
        ...state,
        modifying: true,
      };
    }
    case UPDATE_ACTIVE_CENSUS_SUCCESS: {
      return {
        ...state,
      };
    }
    case UPDATE_ACTIVE_CENSUS_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case DELETE_CENSUS_START: {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case DELETE_CENSUS_SUCCESS: {
      return {
        ...state,
        error: null,
      };
    }
    case DELETE_CENSUS_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    default:
      return state;
  }
};

export const censusDataDictionary = (state = { normal: {}, pooled: {} }, { type, payload }) => {
  switch (type) {
    case GET_CENSUS_DATA_DICTIONARY_SUCCESS: {
      return {
        ...payload
      };
    }
    default:
      return state;
  }
};

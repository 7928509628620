import { combineReducers } from 'redux';

import * as reducers from './reducers.js';
import * as userReducers from '../components/user/reducers.js';
import * as tenantReducers from '../components/tenant/reducers.js';
import * as tenantsReducers from '../components/tenants/reducers.js';
import * as projectsReducers from '../components/projects/reducers.js';
import * as workspaceReducers from '../components/workspace/reducers.js';
import * as projectReducers from '../components/project-setup/reducers.js';
import * as dashboardReducers from '../components/workspace/sidebar/reducers.js';
import * as censusReducers from '../components/project-setup/census-files/reducers.js';
import * as accountingReducers from '../components/project-setup/accounting/reducers.js';
import * as salaryScheduleReducers from '../components/project-setup/salary-increases/salary-schedule-reducers';
import * as payGradesReducers from '../components/project-setup/salary-increases/pay-grades/pay-grades-reducers';
import * as aggCostTablesReducers from '../components/project-setup/agg-cost-tables/reducers';
import * as healthcareFilesReducers from '../components/project-setup/healthcare/reducers';

const allReducers = Object.assign(
  {},
  reducers,
  userReducers,
  censusReducers,
  tenantReducers,
  salaryScheduleReducers,
  payGradesReducers,
  tenantsReducers,
  projectReducers,
  projectsReducers,
  workspaceReducers,
  dashboardReducers,
  accountingReducers,
  aggCostTablesReducers,
  healthcareFilesReducers,
);
export default combineReducers(allReducers);

import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { copyToClipboard } from '../../lib/util';
import govInvestLogo from '../../assets/govinvest_logo_no_subtitle_small.png';
import PrintableContext from './common/printable-context';
import './style.css';

const ProjectTitle = styled.h1`
  display: inline;
  color: #555555;
  font-weight: 400;
  font-size: 16px !important;
  white-space: nowrap;
  margin-bottom: 2.5px;
`;

const ReportTitle = styled.h1`
  text-align: center;
  color: #555555;
  font-size: 24px;
  font-weight: 400;
  margin: 30px 0;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 45px;
`;

const ReportNoteWrapper = styled.div`
  display: flex;
  align-items: center;
  & img {
    max-height: 30px !important;
  }
`;

const ReportNoteText = styled.div`
  font-size: 11px;
  color: #555555;
  white-space: nowrap;
`;

const StyledImage = styled.img`
  max-height: 40px;
  margin-left: 10px;
`;
const StyledTableHeaderCell = styled(TableCell)`
  padding-left: ${props => (props.minify === 'true' ? '5px' : '')} !important;
`;

const StyledTableCell = styled(TableCell)`
  font-size: ${props => (props.minify === 'true' ? '11px' : '12px')} !important;
  padding-left: 5px;
  padding-right: ${props => (props.minify === 'true' ? '5px' : '')};
`;

const StyledTableCellCost = styled(StyledTableCell)`
  padding-left: ${props =>
    props.minify === 'true' ? '5px' : '16px'} !important;
`;

const ChartIcons = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  justify-content: flex-end;
  align-items: center;
`;

const StyledCopyIcon = styled.a`
  color: var(--color-grey-d);
  font-size: 18px !important;
  margin-top: 3px;
  transition: all 0.05s linear;
  &:hover {
    color: var(--color-theme-primary);
    transform: scale(1.1);
    cursor: pointer;
  }
  @media print {
    display: none !important;
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    boxShadow: 'none',
  },
  tableCell: {
    padding: '5px',
    paddingRight: '0px',
    color: '#555555',
  },

  tableHeaderCell: {
    color: 'white',
    paddingRight: '0px',
    background: '#3e4156',
  },
}));

const CostByYearTable = props => {
  const {
    workspace,
    chartOverride,
    chartProperties,
    calculationResults,
    chartTitle,
    selectedCensusFileId,
    selectedPooledCensusFileId,
    selectedSalaryScheduleId,
    selectedHealthcareId,
    selectedPayGradesId,
    selectedPartialAllocationsId,
    calculationType,
    demographics,
  } = props.printData;
  const { currentProposalName } = chartProperties;

  // build totals per year
  const totalsPerYear = [];
  calculationResults.chartYears.forEach((year, yearIndex) => {
    let yearTotal = 0;
    calculationResults[chartOverride].forEach((cost, costIndex) => {
      yearTotal += parseFloat(cost[currentProposalName][yearIndex]);
    });
    totalsPerYear.push(yearTotal);
    yearTotal = 0;
  });

  let grandTotal = 0;
  // build totals per cost
  const totalsPerCost = [];
  calculationResults[chartOverride].forEach(cost => {
    let total = cost[currentProposalName].reduce((a, b) => a + b);
    totalsPerCost.push({
      name: cost.name,
      total: total,
    });
    grandTotal += total;
  });
  const classes = useStyles();
  const minify = calculationResults.chartYears.length >= 8 ? 'true' : 'false';
  return (
    <div className="print-root">
      <ProjectTitle>{workspace.projectName}</ProjectTitle>
      <ReportTitle>{currentProposalName} - {chartTitle}</ReportTitle>
      <ChartIcons>
        <StyledCopyIcon onClick={() => copyToClipboard('cost-by-year-table-printable')} title="Copy data">
          <FontAwesomeIcon icon="copy" />
        </StyledCopyIcon>
      </ChartIcons>
      <Paper className={classes.root}>
        <Table size="small" id={'cost-by-year-table-printable'}>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <StyledTableHeaderCell
                className={classes.tableHeaderCell}
                minify={minify}
              >
                Cost
              </StyledTableHeaderCell>
              {calculationResults.chartYears.map(year => (
                <TableCell
                  key={shortid.generate()}
                  className={classes.tableHeaderCell}
                  align="right"
                >
                  {year}
                </TableCell>
              ))}
              <TableCell className={classes.tableHeaderCell} align="right">
                Totals
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calculationResults[chartOverride].map((cost, costIndex) => (
              <TableRow key={shortid.generate()} className={classes.tableRow}>
                <StyledTableCellCost
                  className={classes.tableCell}
                  minify={minify}
                >
                  {cost.name}
                </StyledTableCellCost>
                {cost[currentProposalName].map(value => (
                  <StyledTableCell
                    key={shortid.generate()}
                    className={classes.tableCell}
                    minify={minify}
                    align="right"
                  >
                    ${Math.ceil(value.toFixed(2)).toLocaleString()}
                  </StyledTableCell>
                ))}
                <StyledTableCell
                  className={classes.tableCell}
                  align="right"
                  minify={minify}
                >
                  $
                  {Math.ceil(totalsPerCost[costIndex].total).toLocaleString()}
                </StyledTableCell>
              </TableRow>
            ))}
            <TableRow className={classes.tableRow}>
              <StyledTableCellCost
                className={classes.tableCell}
                minify={minify}
              >
                Totals
              </StyledTableCellCost>
              {totalsPerYear.map((total, totalIndex) => (
                <StyledTableCell
                  key={shortid.generate()}
                  className={classes.tableCell}
                  minify={minify}
                  align="right"
                >
                  ${Math.ceil(total.toFixed(2)).toLocaleString()}
                </StyledTableCell>
              ))}
              <StyledTableCell
                className={classes.tableCell}
                minify={minify}
                align="right"
              >
                ${Math.ceil(grandTotal.toFixed(2)).toLocaleString()}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <PrintableContext
        workspace={workspace}
        currentProposalName={currentProposalName}
        selectedCensusFileId={selectedCensusFileId}
        selectedPooledCensusFileId={selectedPooledCensusFileId}
        selectedSalaryScheduleId={selectedSalaryScheduleId}
        selectedHealthcareId={selectedHealthcareId}
        selectedPayGradesId={selectedPayGradesId}
        selectedPartialAllocationsId={selectedPartialAllocationsId}
        calculationType={calculationType}
        demographics={demographics}
      />
      <FooterWrapper>
        <div className="print-copyright">
          Confidential &copy; 2021 GovInvest, Inc.
        </div>
        <ReportNoteWrapper>
          <ReportNoteText>This report produced using</ReportNoteText>
          <StyledImage
            src={govInvestLogo}
            alt="govinvest logo"
          />
        </ReportNoteWrapper>
      </FooterWrapper>
    </div>
  );
};

export default CostByYearTable;

// this function is used to build data for both the table view as well as the chart xlsx download
export function buildChartData(calculationResults) {
  const result = {};
  let chartList = [];
  if (calculationResults && calculationResults.chartList) {
    // remove charts with dropdowns/nested data from the chartList (until we determine how to handle them in excel download)
    let chartsWithDropdowns = [];
    for (let chart in calculationResults.chartData) {
      if (!Array.isArray(calculationResults.chartData[chart])) {
        chartsWithDropdowns.push(chart);
      }
    }
    chartList = calculationResults.chartList.filter(
      chart => !chartsWithDropdowns.includes(chart.name)
    );
    // end chart pruning

    chartList.forEach(x => {
      result[x.name] = convertChartDataToTable(
        calculationResults.chartData[x.name]
      );
    });
    const demoData = [];
    const proposalNames = Object.keys(calculationResults.demographicsUnscaled);
    demoData.push(['Proposal', 'Demographic', 'Category', 'Value']);
    proposalNames.forEach(proposalName => {
      const proposalResults =
        calculationResults.demographicsUnscaled[proposalName];
      Object.keys(proposalResults).forEach(demographicName => {
        const demographicResults = proposalResults[demographicName];
        Object.keys(demographicResults).forEach(categoryName => {
          demoData.push([
            proposalName,
            demographicName,
            categoryName,
            demographicResults[categoryName],
          ]);
        });
      });
    });

    result['Demographics'] = demoData;
    return result;
  }
}

export const convertChartDataToTable = chartData => {
  let data = [];
  let columns = Object.keys(chartData[0]);
  const nicelyNamedColumns = columns.map(c => {
    if (c === 'year') {
      return 'Year';
    } else if (c === 'name') {
      return 'Name';
    }
    return c;
  });
  data.push(nicelyNamedColumns);

  chartData.forEach(dataset => {
    const row = [];
    columns.forEach(column => {
      row.push(dataset[column]);
    });
    data.push(row);
  });

  return data;
};

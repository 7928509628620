export const selectChartType = type => ({
  type: 'SELECT_CHART_TYPE',
  payload: type,
});

export const selectActiveChart = type => ({
  type: 'SELECT_ACTIVE_CHART',
  payload: type,
});

export const selectChartDropdownOneIndex = type => ({
  type: 'SELECT_CHART_DROPDOWN_ONE_INDEX',
  payload: type,
});

export const selectChartDropdownTwoIndex = type => ({
  type: 'SELECT_CHART_DROPDOWN_TWO_INDEX',
  payload: type,
});

export const hoverChanged = index => ({
  type: 'HOVER_CHANGED',
  payload: index,
});

export const toggleTopChartCollapse = payload => ({
  type: 'TOGGLE_TOP_CHART_COLLAPSE',
  payload,
});

import React, { useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import TextareaAutosize from 'react-textarea-autosize';

const Notification = props => {
  const [notification, setNotification] = useState(props.notification);

  const resetNotification = () => {
    setNotification(props.notification);
  };

  const notificationIsChanged = updatedNotification => {
    if (!_.isEqual(_.omit(updatedNotification, ['order']), _.omit(props.notification, ['order']))) {
      return true;
    } else {
      return false;
    }
  };

  const setNotificationTitle = value => {
    const updatedNotification = {
      ...notification,
      title: value,
    };

    setNotification(updatedNotification);
  };

  const setNotificationBody = value => {
    const updatedNotification = {
      ...notification,
      body: value,
    };

    setNotification(updatedNotification);
  };

  const handleUploadNotification = notificationToUpload => {
    props.uploadNotification(notificationToUpload);
    resetNotification();
  };

  const addLink = () => {
    let updatedLinks = [...notification.links];
    updatedLinks.push({
      title: '',
      url: '',
    });

    setNotification({
      ...notification,
      links: updatedLinks,
    });
  };

  const removeLink = index => {
    let updatedLinks = [...notification.links];
    updatedLinks = notification.links.filter((link, i) => i !== index);

    setNotification({
      ...notification,
      links: updatedLinks,
    });
  };

  const handleLinkTitle = (linkTitle, index) => {
    let updatedLinks = [...notification.links];
    updatedLinks[index].title = linkTitle;

    setNotification({
      ...notification,
      updatedLinks,
    });
  };

  const handleLinkUrl = (linkUrl, index) => {
    let updatedLinks = [...notification.links];
    updatedLinks[index].url = linkUrl;

    setNotification({
      ...notification,
      updatedLinks,
    });
  };

  const validation = () => {
    let errors = {};

    if (notification.title.trim() === '') errors['title'] = 'Title cannot be blank';
    if (notification.body.trim() === '') errors['body'] = 'Body cannot be blank';
    notification.links.forEach((link, linkIndex) => {
      if (link.title.trim() === '')
        errors['link-title'] = {
          [linkIndex]: 'Link title cannot be blank',
        };
      if (link.url.trim() === '')
        errors['link-url'] = {
          [linkIndex]: 'Link url cannot be blank',
        };
    });

    return errors;
  };

  const validationErrors = validation();
  const editing = notificationIsChanged(notification);

  const renderCancelButton = () => {
    if (editing) {
      return <SecondaryButton onClick={resetNotification}>Cancel</SecondaryButton>;
    }
  };

  const renderSubmitEditButton = () => {
    if (Object.keys(validationErrors).length > 0) {
      return <DisabledButton>Save Changes</DisabledButton>;
    } else {
      return (
        <SubmitButton
          $disabled={Object.keys(validationErrors).length > 0}
          onClick={() => props.updateNotification(notification)}
        >
          Save Changes
        </SubmitButton>
      );
    }
  };

  const renderSubmitNewButton = () => {
    if (Object.keys(validationErrors).length > 0) {
      return <DisabledButton>Add</DisabledButton>;
    } else {
      return (
        <SubmitButton
          $disabled={Object.keys(validationErrors).length > 0}
          onClick={() => handleUploadNotification(notification)}
        >
          Add
        </SubmitButton>
      );
    }
  };

  return (
    <CardStyled key={notification.id}>
      <CardTitleRow>
        <NotificationTitle
          onChange={e => setNotificationTitle(e.target.value)}
          placeholder="Notification Title"
          value={notification.title}
        />
      </CardTitleRow>

      <CardBody>
        <NotificationBody
          placeholder="Notification Body"
          value={notification.body}
          onChange={e => setNotificationBody(e.target.value)}
        />
        {notification.links.length > 0 && (
          <CardLinks>
            {notification.links.map((link, linkIndex) => (
              <LinksContainer key={`notification-controls-link-${notification.id}-${linkIndex}`}>
                <LinkTitle
                  onChange={e => handleLinkTitle(e.target.value, linkIndex)}
                  placeholder="Link Title"
                  value={link.title}
                />
                <LinkUrl
                  onChange={e => handleLinkUrl(e.target.value, linkIndex)}
                  placeholder="Link URL"
                  value={link.url}
                />
                <RemoveLinkButton onClick={() => removeLink(linkIndex)}>
                  Remove link
                </RemoveLinkButton>
              </LinksContainer>
            ))}
          </CardLinks>
        )}
      </CardBody>

      {!props.newNotification && <Date>Added on {dayjs(notification.date).format('MMMM D, YYYY')}</Date>}

      {props.newNotification && (
        <CardFooter>
          <AddLinkButton onClick={addLink}>Add link</AddLinkButton>
          {renderSubmitNewButton()}
        </CardFooter>
      )}

      {!props.newNotification && (
        <CardFooter>
          <AddLinkButton onClick={addLink}>Add link</AddLinkButton>
          {editing && (
            <>
              {renderSubmitEditButton()}
              {renderCancelButton()}
            </>
          )}
          {!editing && (
            <>
              <DeleteButton onClick={() => props.deleteNotification(props.notification.id)}>
                Delete
              </DeleteButton>
            </>
          )}
        </CardFooter>
      )}
    </CardStyled>
  );
};

export default Notification;

const EditableTextArea = styled(TextareaAutosize)`
  width: 100%;
  font-family: Roboto;
  border: 0px solid transparent;
  &:focus {
    outline: 1px solid var(--color-theme-primary);
  }
  &::placeholder {
    font-size: 14px;
    font-family: Roboto;
  }
`;

const NotificationTitle = styled(EditableTextArea)`
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
  &::placeholder {
    font-size: 16px;
  }
`;

const NotificationBody = styled(EditableTextArea)`
  font-size: 14px;
`;

const LinksContainer = styled.div`
  display: grid;
  grid-row-gap: 2.5px;
  margin-bottom: 2.5px;
`;

const LinkTitle = styled(EditableTextArea)`
  font-size: 14px;
`;

const LinkUrl = styled(EditableTextArea)`
  font-size: 14px;
`;

const CardStyled = styled(Card)`
  margin-bottom: 5px;
`;

const CardButton = styled.div`
  width: fit-content;
  font-size: 12px;
  padding: 3px 7px 2.5px 7px;
  background: var(--color-lightgrey);
  border-radius: 2.5px;
  transition: 0.1s ease;
  user-select: none;
  cursor: pointer;
`;

const SubmitButton = styled(CardButton)`
  color: white;
  background: var(--color-theme-primary);
  &:hover {
    background: var(--color-theme-primary-d);
  }
`;

const SecondaryButton = styled(CardButton)`
  color: black;
  background: var(--color-lightgrey);
  &:hover {
    color: white;
    background: var(--color-grey);
  }
`;

const DeleteButton = styled(CardButton)`
  color: white;
  background: var(--color-red);
  &:hover {
    background: var(--color-red-d);
  }
`;

const DisabledButton = styled(CardButton)`
  color: black;
  background: var(--color-lightgrey);
  &:hover {
    background: var(--color-lightgrey);
  }
`;

const AddLinkButton = styled(SubmitButton)`
  justify-self: flex-start;
`;

const RemoveLinkButton = styled(CardButton)`
  margin: 2.5px 0 0 0;
  &:hover {
    color: white;
    background: var(--color-grey);
  }
`;

const CardFooter = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto auto;
  grid-column-gap: 10px;
  align-items: center;
  justify-items: flex-end;
  margin: 0px 5px 5px 20px;
`;

const CardTitleRow = styled.div`
  margin: 0 10px;
`;

const CardBody = styled.div`
  height: auto;
  font-size: 14px;
  padding: 0 5px 0 20px;
  margin: 0 0 5px 0;
`;

const CardLinks = styled.div`
  display: grid;
  margin: 5px 0 0 0;
`;

const Date = styled.div`
  color: var(--color-grey);
  font-size: 12px;
  margin 5px 0 5px 20px;
`;

import reducers from '../reducers';
import thunk from './redux-thunk.js';
import reporter from './redux-reporter.js';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { watchCalculationsSaga } from '../components/workspace/calculation-sagas';

const sagaMiddleware = createSagaMiddleware();
export default createStore(
  reducers,
  applyMiddleware(thunk, reporter, sagaMiddleware)
);

sagaMiddleware.run(watchCalculationsSaga);

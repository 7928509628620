import {
  CLEAR_USER,
  FETCH_USER_BY_EMAIL_SLUG_START,
  FETCH_USER_BY_EMAIL_SLUG_SUCCESS,
  FETCH_USER_BY_EMAIL_SLUG_FAILURE,
  FETCH_USER_TENANTS_BY_EMAIL_SLUG_START,
  FETCH_USER_TENANTS_BY_EMAIL_SLUG_SUCCESS,
  FETCH_USER_TENANTS_BY_EMAIL_SLUG_FAILURE,
  FETCH_USER_TENANTS_SUCCESS,
  FETCH_USER_NOTIFICATIONS_SUCCESS,
} from '../user/types';

const userInitialState = {
  user: {},
  loading: false,
  fetched: false,
  error: null,
};

export const user = (state = userInitialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loading: true,
        fetched: false,
        error: null,
        notifications: payload.notifications,
      };
    }
    case FETCH_USER_BY_EMAIL_SLUG_START: {
      return {
        ...state,
        loading: true,
        fetched: false,
        error: null,
      };
    }
    case FETCH_USER_BY_EMAIL_SLUG_SUCCESS: {
      return {
        ...state,
        loading: false,
        fetched: true,
        user: payload,
      };
    }
    case FETCH_USER_BY_EMAIL_SLUG_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload,
        fetched: true,
        user: null,
      };
    }
    case CLEAR_USER: {
      return userInitialState;
    }
    default: {
      return state;
    }
  }
};

const userTenantsInitialState = {
  userTenants: [],
  loading: false,
  fetched: false,
  error: null,
};

export const userTenants = (
  state = userTenantsInitialState,
  { type, payload }
) => {
  switch (type) {
    case FETCH_USER_TENANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        userTenants: payload.tenants,
        fetched: true,
        error: null,
      };
    case FETCH_USER_TENANTS_BY_EMAIL_SLUG_START:
      return {
        ...state,
        loading: true,
        fetched: false,
        error: null,
      };
    case FETCH_USER_TENANTS_BY_EMAIL_SLUG_SUCCESS:
      return {
        ...state,
        loading: false,
        userTenants: payload,
        fetched: true,
        error: null,
      };
    case FETCH_USER_TENANTS_BY_EMAIL_SLUG_FAILURE:
      return {
        ...state,
        loading: false,
        fetched: true,
        error: payload,
      };
    default:
      return state;
  }
};

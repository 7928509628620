import React, { useState, useEffect, useRef } from 'react';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import styled from 'styled-components';

// Material-ui modals have z-index of 1300
// Poppers appear behind them unless given a greater z-index
const PopperStyled = styled(Popper)`
  z-index: 1301;
`;

const TitleStyled = styled.div`
  width: max-content;
`;

const MyPopper = props => {
  const anchorRef = useRef(null);
  const [showPopper, setShowPopper] = useState(false);
  const popperOpenedCallback = props.popperOpenedCallback;
  const popperClosedCallback = props.popperClosedCallback;

  useEffect(() => {
    if (props.$startOpened && !showPopper) {
      setShowPopper(true)
    }
  }, [props.$startOpened, showPopper]);

  const handleShowPopperToggle = () => {
    if (!props.disableClose) {
      if (showPopper) {
        handleClose();
      }
      if (!showPopper) {
        handleOpen();
      }
    }
  };

  const handleOpen = () => {
    if (popperOpenedCallback) {
      popperOpenedCallback(showPopper);
    }
    setShowPopper(true);
  };

  const handleClose = () => {
    if (!props.disableClose) {
      if (popperClosedCallback) {
        popperClosedCallback(showPopper)
      }
      setShowPopper(false);
    }
  };

  return (
    <>
      <TitleStyled
        ref={anchorRef}
        onClick={handleShowPopperToggle}
        aria-haspopup="true"
        aria-owns={showPopper ? 'menu-list-grow' : undefined}
      >
        {props.titleComponent}
      </TitleStyled>
      <PopperStyled
        open={showPopper}
        anchorEl={anchorRef.current}
        transition
        placement={props.placement || 'bottom-end'}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              style={props.$popperStylesOverride ? { ...props.$popperStylesOverride } : {}}
              onClick={() => (props.closeOnClick ? setShowPopper(false) : undefined)}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  {props.passHandleClose
                    ? React.cloneElement(props.poppedComponent, {
                        handleClose: handleClose,
                      })
                    : props.poppedComponent}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </PopperStyled>
    </>
  );
};

export default MyPopper;

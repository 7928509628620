import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import CardActionArea from '@material-ui/core/CardActionArea';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Logger from '../../lib/util';
import * as config from '../../lib/config';
import MyPopper from '../common/my-popper';
import Notification from '../common/notification';
import { fetchUserNotifications } from '../user/actions';
import * as notificationsHelper from './notifications-helper';
import Auth from '../../auth/auth';

const Notifications = () => {
  const [notificationsData, setNotificationsData] = useState();
  const userNotifications = useSelector(rs => rs.user.notifications);
  const profile = useSelector(rs => rs.profile);
  const dispatch = useDispatch();
  const logger = new Logger();

  useEffect(() => {
    if (!profile || !profile.userId) {
      return;
    }

    dispatch(fetchUserNotifications(profile.userId));
    getNotifications();

    const id = setInterval(() => getNotifications(), 10000);

    return () => clearInterval(id);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const postViewedNotificationsIfNeeded = async () => {
    if (numberOfNewNotifications === 0) {
      return;
    }

    const viewedNotificationIds = model.newNotificationIds;

    if (viewedNotificationIds.length > 0) {
      const command = {
        type: 'VIEW_NOTIFICATIONS',
        tenantId: profile.tenantId,
        viewedNotificationIds,
      };

      await postCommand(command);
      if(profile && profile.userId) {
        dispatch(fetchUserNotifications(profile.userId));
      }
    }
  };

  const fetchNotificationsData = async () => {
    new Auth().checkAuth();
    const url = `${config.baseURL()}/api/notifications`;

    return await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(res => res.json())
      .then(res => {
        logger.debug(`get notifications success`);
        return res;
      })
      .catch(error => {
        logger.error('get notifications failed', error);
      });
  };

  const getNotifications = async () => {
    const notificationsData = await fetchNotificationsData();
    setNotificationsData(notificationsData);
    dispatch(fetchUserNotifications(profile.userId));
  };

  const postDismissedNotificationIds = async dismissedNotificationIds => {
    const command = {
      type: 'DISMISS_NOTIFICATIONS',
      tenantId: profile.tenantId,
      dismissedNotificationIds,
    };
    await postCommand(command);
    dispatch(fetchUserNotifications(profile.userId));
  };

  const postCommand = async command => {
    const url = `${config.baseURL()}/api/commands`;
    new Auth().checkAuth();

    return await fetch(url, {
      method: 'post',
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(command),
    })
      .then(res => res.json())
      .then(res => {
        logger.debug('post command notifications api success response: ', res);
        return res;
      })
      .catch(error => {
        logger.debug('post command notifications api failure response: ', error);
      });
  };

  if(!userNotifications) {
    return <div></div>;
  }

  const model = notificationsHelper.buildUserNotificationsModel(notificationsData, userNotifications);
  const numberOfNewNotifications = model.numberOfNew;
  const notifications = model.userNotifications;

  return (
    <NotificationsWrapper>
      <MyPopper
        popperClosedCallback={postViewedNotificationsIfNeeded}
        placement={'bottom-start'}
        titleComponent={
          <NotificationsAlertIconContainer>
            <NotificationsAlert $newNotifications={numberOfNewNotifications > 0}>
              {numberOfNewNotifications}
            </NotificationsAlert>
            <NotificationsNoneIconStyled $hasNotifications={notifications.length > 0} />
          </NotificationsAlertIconContainer>
        }
        poppedComponent={
          <NotificationsContent>
            <NotificationsContainer>
              {notifications.length > 0 &&
                notifications.map(notification => (
                  <Notification
                    notification={notification}
                    dismissNotification={() => postDismissedNotificationIds([notification.id])}
                    useBottomMargin={notifications.length > 1}
                    key={notification.id}
                  />
                ))}
            </NotificationsContainer>
            {notifications.length === 0 && (
              <NoNotificationsContainer>
                <NoNotificationsIcon />
                <EmptyNotificationsTitle>No new notifications</EmptyNotificationsTitle>
              </NoNotificationsContainer>
            )}
            {notifications.length > 1 && (
              <DismissAllButton onClick={() => postDismissedNotificationIds(model.allNotificationIds)}>
                Dismiss All
              </DismissAllButton>
            )}
          </NotificationsContent>
        }
      />
    </NotificationsWrapper>
  );
};

export default Notifications;

const NotificationsWrapper = styled.div`
  margin: 6px 0 0 0;
`;

const NotificationsContent = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  min-width: 360px;
  max-width: 360px;
`;

const NotificationsContainer = styled.div`
  max-height: 90vh;
  overflow-y: auto;
`;

const DismissAllButton = styled(CardActionArea)`
  display: grid;
  font-size: 12px;
  color: var(--color-grey);
  background: var(--color-lightgrey);
  padding: 5px 5px 5px 5px;
  &:hover {
    color: white;
    background: var(--color-grey);
  }
`;

const NotificationsAlertIconContainer = styled.div`
  position: relative;
`;

const NotificationsAlert = styled.div`
  position: absolute;
  display: ${props => (props.$newNotifications ? 'flex' : 'none')};
  width: 19px;
  height: 19px;
  max-width: 19px;
  max-height: 19px;
  color: white;
  background: var(--color-blue);
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 50%;
  left: -5p;
  left: -5px;
  top: -5px;
  cursor: pointer;
`;

const NoNotificationsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 15px;
  align-items: center;
  justify-content: flex-start;
  margin: 0 10px;
`;

const NoNotificationsIcon = styled(InfoOutlinedIcon)`
  color: var(--color-lightgrey);
  font-size: 3em;
  margin: 9px 0;
`;

const EmptyNotificationsTitle = styled.h2`
  color: var(--color-grey);
  font-size: 1.5em;
  margin: 0;
`;

const NotificationsNoneIconStyled = styled(NotificationsNoneIcon)`
  color: ${props => (props.$hasNotifications ? 'var(--color-theme-primary)' : 'var(--color-grey)')};
  cursor: pointer;
  &:hover {
    color: ${props =>
    props.$hasNotifications ? 'var(--color-theme-primary-d)' : 'var(--color-black)'};
  }
`;

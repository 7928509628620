import React, { Component } from 'react';
import {
  Line,
  Label,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import { colors, chartColors } from '../../style/colors';

class CostLineChart extends Component {
  ctx = CanvasRenderingContext2D;
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.calculateOnUpdate) {
      return true;
    } else {
      if (nextProps.lastUpdateType === 'calculation') {
        return true;
      } else {
        return false;
      }
    }
  }

  getContractStartDay(date) {
    return moment(date).subtract(1, 'days').format('D');
  }

  getContractStartMonth(date) {
    return moment(date).subtract(1, 'days').format('MMMM');
  }

  getActiveYear(date) {
    return Number.parseInt(moment(date).format('YYYY'), 10) + this.props.selectedYearIndex;
  }

  measureText = text => {
    this.ctx = document.createElement('canvas').getContext('2d');
    this.ctx.font = '12px "Roboto"';

    return this.ctx.measureText(text).width;
  };

  leftMargin = () => {
    let leftMargin = 0;
    for (const data of this.props.chartData) {
      const textWidth = this.measureText(data.year.toString().split(' ')[0]);

      if (textWidth > leftMargin) {
        leftMargin = textWidth;
      }
    }
    leftMargin = Math.max(0, leftMargin - 80);
    return leftMargin;
  };

  chartColors = () => {
    let newChartColors = [...chartColors];
    return newChartColors;
  };

  render() {
    const newChartColors = this.chartColors();
    const leftMargin = this.leftMargin();
    const contractStartMonth = this.getContractStartMonth(
      this.props.contractStartDate
    );
    const contractStartDay = this.getContractStartDay(
      this.props.contractStartDate
    );
    const formatter = value =>
      `$${(Math.round((value / this.props.chartDivisor) * 100) / 100).toFixed(
        2
      )}${this.props.chartScale}`;
    let isAnimationActive = true;
    if (this.props.disableAnimation) {
      isAnimationActive = false;
    }

    let isToolTipsActive = true;
    if (this.props.disableToolTips) {
      isToolTipsActive = false;
    }
    let yMin = 100000000000;
    let yMax = 0;
    this.props.chartData.forEach(dataPoint => {
      this.props.proposalNames.forEach(proposalName => {
        if (dataPoint[proposalName]) {
          yMin = Math.min(yMin, dataPoint[proposalName]);
          yMax = Math.max(yMax, dataPoint[proposalName]);
        }
      });
    });
    yMin = yMin * 0.9;
    yMax = yMax * 1.1;

    return (
      <React.Fragment>
        <ResponsiveContainer height="100%" width="99%">
          <LineChart
            data={this.props.chartData}
            margin={{ top: 0, right: 30, left: leftMargin, bottom: 5 }}
            onMouseMove={e => this.props.showComparisons(e)}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year">
              <Label
                value={`Year ending ${contractStartMonth} ${contractStartDay} of`}
                position="insideBottom"
                offset={-2}
                style={{ color: '#555555' }}
              />
            </XAxis>
            <YAxis tickFormatter={formatter} domain={[yMin, yMax]} />

            {isToolTipsActive && (
              <Tooltip formatter={formatter} isAnimationActive={false} />
            )}

            <Line
              type="linear"
              dataKey="Baseline"
              stroke={newChartColors[0]}
              strokeWidth={this.props.printView ? 2 : 4}
              isAnimationActive={isAnimationActive}
            />
            {this.props.proposalIsChanged && (
              <Line
                type="linear"
                dataKey={this.props.currentProposalImpactName}
                stroke={colors.lightblue}
                strokeWidth={this.props.printView ? 2 : 4}
                strokeDasharray="4 4"
                isAnimationActive={isAnimationActive}
              />
            )}
            <Line
              type="linear"
              dataKey={this.props.currentProposalName}
              stroke={newChartColors[1]}
              strokeWidth={this.props.printView ? 2 : 4}
              isAnimationActive={isAnimationActive}
            />

            {this.props.comparedProposalsNames.map((name, i) => {
              return (
                <Line
                  type="linear"
                  key={name}
                  dataKey={name}
                  stroke={newChartColors[i + 2]}
                  strokeWidth={3}
                  isAnimationActive={isAnimationActive}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </React.Fragment>
    );
  }
}

export default CostLineChart;

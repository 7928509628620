import React from 'react';

function InfoMessage(props) {
  const {title, detail} = props;
  return (
    <div style={{margin:'30px', marginTop:'100px', display:'flex', flexDirection:'column', alignItems:'center'}}>
      <div style={{fontSize:'2em', marginBottom:'20px'}}>{title}</div>
      <div style={{marginBottom: '20px'}}><label style={{fontWeight:'bold'}}>Description: </label>{detail}</div>
    </div>
    );
};

export default InfoMessage;
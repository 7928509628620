import * as types from './types';

const aggCostTableProgressInitialState = {
  percent: 0,
  uploading: false,
  validating: false,
  validated: false,
  error: null,
  response: {},
};

export const aggCostTableUploadProgress = (
  state = aggCostTableProgressInitialState,
  { type, payload }
) => {
  switch (type) {
    case types.GET_AGG_COST_TABLE_SAS_TOKEN_START: {
      return {
        ...state,
        uploading: true,
        validating: false,
      };
    }
    case types.GET_AGG_COST_TABLE_SAS_TOKEN_SUCCESS: {
      return { ...state };
    }
    case types.GET_AGG_COST_TABLE_SAS_TOKEN_FAILURE: {
      return { ...state };
    }
    case types.UPLOAD_AGG_COST_TABLE_START:
      return {
        ...state,
        uploading: true,
      };
    case types.UPLOAD_AGG_COST_TABLE_SUCCESS:
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case types.UPLOAD_AGG_COST_TABLE_FAILURE:
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case types.UPDATE_AGG_COST_TABLE_UPLOAD_PROGRESS: {
      return {
        ...state,
        percent: payload,
      };
    }
    case types.UPDATE_AGG_COST_TABLE_STATUS_START: {
      return {
        ...state,
        validating: true,
        validated: false,
        modifying: true,
        error: null,
      };
    }
    case types.UPDATE_AGG_COST_TABLE_STATUS_SUCCESS: {
      return {
        ...state,
        modifying: false,
        error: null,
      };
    }
    case types.UPDATE_AGG_COST_TABLE_STATUS_FAILURE: {
      return {
        ...state,
        validating: false,
        validated: false,
        modifying: false,
        error: payload,
      };
    }
    case types.GET_AGG_COST_TABLE_BY_ID_START: {
      return {
        ...state,
        validating: true,
        validated: false,
        error: null,
      };
    }
    case types.GET_AGG_COST_TABLE_BY_ID_SUCCESS: {
      return {
        ...state,
        validating: false,
        validated: true,
        error: false,
        response: payload,
      };
    }
    case types.GET_AGG_COST_TABLE_BY_ID_INVALID: {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case types.GET_AGG_COST_TABLE_BY_ID_FAILURE: {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case types.CLEAR_AGG_COST_TABLE_UPLOAD_PROGRESS: {
      return aggCostTableProgressInitialState;
    }
    default:
      return state;
  }
};

const aggCostTableInitialState = {
  loading: false,
  modifying: false,
  error: null,
  aggCostTables: [],
};

export const aggCostTables = (
  state = aggCostTableInitialState,
  { type, payload }
) => {
  switch (type) {
    case types.GET_AGG_COST_TABLE_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case types.GET_AGG_COST_TABLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: null,
        aggCostTables: payload,
      };
    }
    case types.GET_AGG_COST_TABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: payload,
      };
    }
    case types.UPDATE_ACTIVE_AGG_COST_TABLE_START: {
      return {
        ...state,
        modifying: true,
      };
    }
    case types.UPDATE_ACTIVE_AGG_COST_TABLE_SUCCESS: {
      return {
        ...state,
        modifying: false,
      };
    }
    case types.UPDATE_ACTIVE_AGG_COST_TABLE_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case types.DELETE_AGG_COST_TABLE_START: {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case types.DELETE_AGG_COST_TABLE_SUCCESS: {
      return {
        ...state,
        modifying: false,
        error: null,
      };
    }
    case types.DELETE_AGG_COST_TABLE_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    default:
      return state;
  }
};

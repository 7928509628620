import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import history from './lib/history.js';
import store from './lib/store-create';
import App from './app.js';
import Auth from './auth/auth.js';
import Callback from './components/callback/callback.js';
import CssBaseline from '@material-ui/core/CssBaseline';
import Printable from './components/printable';
import LoginFailed from './components/loginFailed/LoginFailed';
import './style/main.css';

const notFound = () => <div>Page not found</div>;

const Routes = () => {
  const [started, setStarted] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [authorizedScope, setAuthorizedScope] = useState(false);
  const [authStartResult, setAuthStartResult] = useState(null);

  useEffect(() => {
    const setupAuth = async () => {
      if (window.location.pathname === '/callback') {
        const startResult = await new Auth().handleStart();
        console.log('setting auth start result');
        setAuthStartResult(startResult);
      } else {
        const a = new Auth();
        const loggedIn = await a.testCookieCredentials();
        setLoggedIn(loggedIn);
        const authorizedScope = a.hasAuthorizedScope();
        setAuthorizedScope(authorizedScope);
      }
      setStarted(true);
    }
    if (!started) {
      setupAuth();
    }
  }, [started]);

  const auth = new Auth();
  if(window.location.pathname === '/logout_callback') {
    auth.handleStart();
    return <Callback />
  }

  if(window.location.pathname === '/callback') {
    if(!authStartResult) {
      console.log('waiting for auth start result');
      return <Callback />
    }
    console.log('auth start result available');
    if(authStartResult.error) {
      return <LoginFailed error={authStartResult.error} errorDescription={authStartResult.errorDescription} />
    }
  }

  if (window.location.pathname.indexOf('printable') !== -1) {
    return <Printable store={store} />;
  }

  if(started && loggedIn && !authorizedScope) {
    console.log('No authorized scope');
    return <LoginFailed error="not_authorized" errorDescription="You do not have access to this module.  Please contact GovInvest support or your local administrator." />
  }

  if (started && !loggedIn && window.location.pathname !== '/callback') {
    auth.initiateLogin();
    return <Callback />
  }

  if(!started) {
    console.log('Waiting to start');
    return <Callback />;
  }

  const isCallback = window.location.pathname === '/callback';

  return (
    <Provider store={store}>
      <React.Fragment>
        <CssBaseline />
        <Router history={history} component={App}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/app" />
            </Route>
            { isCallback && (
              <Route exact path="/callback">
                <Redirect to={auth.getRequestedUrl()} />
              </Route>
            )}
            <Route
              path="/app"
              render={props => <App auth={auth} {...props} />}
            />
            <Route exact path="*" component={notFound} />
          </Switch>
        </Router>
      </React.Fragment>
    </Provider>
  );
};

export default Routes;

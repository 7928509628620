import React from 'react';
import styled from 'styled-components';

const Banner = styled.div`
  color: white;
  font-size: var(--font-s);
  background-color: var(--color-red);
  padding: 3px 5px 2.5px 5px;
  border-radius: 2.5px;
`;

const ProductionBanner = () => {
  return <Banner>Production</Banner>;
};

export default ProductionBanner;

import dayjs from 'dayjs';

export const buildUserNotificationsModel = (notificationsData, user) => {
  if(!notificationsData) {
    return {
      userNotifications: [],
      numberOfNew: 0,
      newNotificationIds: [],
      allNotificationIds: [],
    }
  }

  const allNotifications = notificationsData.notifications;
  let dismissedIds = user.dismissedNotificationIds;
  const viewedIds = user.viewedNotificationIds;

  const userNotifications = [];
  const newNotificationIds = [];
  const allNotificationIds = [];

  let numberOfNew = 0;

  for(let notification of allNotifications) {
    if(dismissedIds.indexOf(notification.id) !== -1) { continue; }

    allNotificationIds.push(notification.id);

    if(viewedIds.indexOf(notification.id) !== -1) {
      notification.status = 'viewed';
    } else {
      notification.status = 'new';
      numberOfNew++;
      newNotificationIds.push(notification.id);
    }

    userNotifications.push(notification);
  }

  const sortedUserNotifications = userNotifications.sort((a,b) => dayjs(b.date) - dayjs(a.date));

  return {
    userNotifications: sortedUserNotifications,
    numberOfNew: numberOfNew,
    newNotificationIds,
    allNotificationIds,
  }
};
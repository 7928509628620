import { DISMISS_ERROR } from '../../shared/common/constants';

import {
  FETCH_PROJECT_START,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  CLEAR_PROJECT,
} from './types';

import {
  SAVE_PROJECT_INFO_START,
  SAVE_PROJECT_INFO_SUCCESS,
  SAVE_PROJECT_INFO_FAILURE,
} from './project-info/types';

import {
  ADD_GROUP_TO_PROJECT_START,
  ADD_GROUP_TO_PROJECT_SUCCESS,
  ADD_GROUP_TO_PROJECT_FAILURE,
  EDIT_GROUP_START,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_FAILURE,
  DELETE_GROUP_START,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
} from './groups/types';

import {
  ADD_SALARY_INCREASE_TO_PROJECT_START,
  ADD_SALARY_INCREASE_TO_PROJECT_SUCCESS,
  ADD_SALARY_INCREASE_TO_PROJECT_FAILURE,
  EDIT_SALARY_INCREASE_START,
  EDIT_SALARY_INCREASE_SUCCESS,
  EDIT_SALARY_INCREASE_FAILURE,
  DELETE_SALARY_INCREASE_START,
  DELETE_SALARY_INCREASE_SUCCESS,
  DELETE_SALARY_INCREASE_FAILURE,
} from './salary-increases/types';

import {
  UPDATE_ACTIVE_CENSUS_START,
  UPDATE_ACTIVE_CENSUS_SUCCESS,
  UPDATE_ACTIVE_CENSUS_FAILURE,
  DELETE_CENSUS_START,
  DELETE_CENSUS_SUCCESS,
  DELETE_CENSUS_FAILURE,
} from './census-files/types';

import {
  GET_ACCOUNTING_MATRIX_START,
  GET_ACCOUNTING_MATRIX_SUCCESS,
  GET_ACCOUNTING_MATRIX_FAILURE,
  UPDATE_ACTIVE_ACCOUNTING_MATRIX_START,
  UPDATE_ACTIVE_ACCOUNTING_MATRIX_SUCCESS,
  UPDATE_ACTIVE_ACCOUNTING_MATRIX_FAILURE,
} from './accounting/types';

import {
  GET_PARTIAL_ALLOCATION_SUCCESS,
  GET_PARTIAL_ALLOCATION_FAILURE,
  UPDATE_ACTIVE_PARTIAL_ALLOCATION_START,
  UPDATE_ACTIVE_PARTIAL_ALLOCATION_SUCCESS,
  UPDATE_ACTIVE_PARTIAL_ALLOCATION_FAILURE,
} from './accounting/types';

import {
  GET_AGG_COST_TABLE_FAILURE,
  GET_AGG_COST_TABLE_START,
  GET_AGG_COST_TABLE_SUCCESS
} from './agg-cost-tables/types';

const projectSetupHeaderInitialState = {
  name: '',
  tabs: [],
  activeTab: '',
  description: '',
};

export const projectSetupHeader = (
  state = projectSetupHeaderInitialState,
  { type, payload }
) => {
  switch (type) {
    case 'SET_PROJECT_SETUP_HEADER': {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};

const projectInitialState = {
  project: {},
  loading: false,
  modifying: false,
  error: null,
  setup: false,
  fileUploading: false,
};

export const project = (state = projectInitialState, { type, payload }) => {
  switch (type) {
    case 'SEND_COMMAND_START': {
      return {
        ...state,
        error: null,
        modifying: true,
      };
    }
    case 'SEND_COMMAND_SUCCESS': {
      return {
        ...state,
        error: null,
      };
    }
    case 'SEND_COMMAND_FAILURE': {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case FETCH_PROJECT_START: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case FETCH_PROJECT_SUCCESS: {
      return {
        ...state,
        project: payload,
        error: null,
        loading: false,
        modifying: false,
      };
    }
    case FETCH_PROJECT_FAILURE: {
      return {
        ...state,
        project: {},
        loading: false,
        modifying: false,
        error: payload,
      };
    }
    case SAVE_PROJECT_INFO_START: {
      return {
        ...state,
        error: null,
        modifying: true,
      };
    }
    case SAVE_PROJECT_INFO_SUCCESS: {
      return state;
    }
    case SAVE_PROJECT_INFO_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case ADD_GROUP_TO_PROJECT_START: {
      return {
        ...state,
        error: null,
        modifying: true,
      };
    }
    case ADD_GROUP_TO_PROJECT_SUCCESS: {
      return state;
    }
    case ADD_GROUP_TO_PROJECT_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case EDIT_GROUP_START: {
      return {
        ...state,
        error: null,
        modifying: true,
      };
    }
    case EDIT_GROUP_SUCCESS: {
      return state;
    }
    case EDIT_GROUP_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case DELETE_GROUP_START: {
      return {
        ...state,
        error: null,
        modifying: true,
      };
    }
    case DELETE_GROUP_SUCCESS: {
      return state;
    }
    case DELETE_GROUP_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case ADD_SALARY_INCREASE_TO_PROJECT_START: {
      return {
        ...state,
        error: null,
        modifying: true,
      };
    }
    case ADD_SALARY_INCREASE_TO_PROJECT_SUCCESS: {
      return state;
    }
    case ADD_SALARY_INCREASE_TO_PROJECT_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case EDIT_SALARY_INCREASE_START: {
      return {
        ...state,
        error: null,
        modifying: true,
      };
    }
    case EDIT_SALARY_INCREASE_SUCCESS: {
      return state;
    }
    case EDIT_SALARY_INCREASE_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case DELETE_SALARY_INCREASE_START: {
      return {
        ...state,
        error: null,
        modifying: true,
      };
    }
    case DELETE_SALARY_INCREASE_SUCCESS: {
      return state;
    }
    case DELETE_SALARY_INCREASE_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case UPDATE_ACTIVE_CENSUS_START: {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case UPDATE_ACTIVE_CENSUS_SUCCESS: {
      return {
        ...state,
        error: null,
        modifying: false,
      };
    }
    case UPDATE_ACTIVE_CENSUS_FAILURE: {
      return {
        ...state,
        error: payload,
        modifying: false,
      };
    }
    case DELETE_CENSUS_START: {
      return {
        ...state,
        modifying: true,
      };
    }
    case DELETE_CENSUS_SUCCESS: {
      return {
        ...state,
        modifying: false,
      };
    }
    case DELETE_CENSUS_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case GET_ACCOUNTING_MATRIX_START: {
      return {
        ...state,
        modifying: true,
      };
    }
    case GET_ACCOUNTING_MATRIX_SUCCESS: {
      return {
        ...state,
        modifying: false,
      };
    }
    case GET_ACCOUNTING_MATRIX_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case UPDATE_ACTIVE_ACCOUNTING_MATRIX_START: {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case UPDATE_ACTIVE_ACCOUNTING_MATRIX_SUCCESS: {
      return {
        ...state,
        error: null,
      };
    }
    case UPDATE_ACTIVE_ACCOUNTING_MATRIX_FAILURE: {
      return {
        ...state,
        error: payload,
      };
    }
    case GET_AGG_COST_TABLE_START: {
      return {
        ...state,
        modifying: true,
      };
    }
    case GET_AGG_COST_TABLE_SUCCESS: {
      return {
        ...state,
        modifying: false,
      };
    }
    case GET_AGG_COST_TABLE_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case 'GET_SALARY_SCHEDULE_START': {
      return {
        ...state,
        modifying: true,
      };
    }
    case 'GET_SALARY_SCHEDULE_SUCCESS': {
      return {
        ...state,
        modifying: false,
      };
    }
    case 'GET_SALARY_SCHEDULE_FAILURE': {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case 'UPDATE_ACTIVE_SALARY_SCHEDULE_START': {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case 'UPDATE_ACTIVE_SALARY_SCHEDULE_SUCCESS': {
      return {
        ...state,
        error: null,
      };
    }
    case 'UPDATE_ACTIVE_SALARY_SCHEDULE_FAILURE': {
      return {
        ...state,
        error: payload,
      };
    }
    case 'GET_PAY_GRADES_START': {
      return {
        ...state,
        modifying: true,
      };
    }
    case 'GET_PAY_GRADES_SUCCESS': {
      return {
        ...state,
        modifying: false,
      };
    }
    case 'GET_PAY_GRADES_FAILURE': {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case 'UPDATE_ACTIVE_PAY_GRADES_START': {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case 'UPDATE_ACTIVE_PAY_GRADES_SUCCESS': {
      return {
        ...state,
        error: null,
      };
    }
    case 'UPDATE_ACTIVE_PAY_GRADES_FAILURE': {
      return {
        ...state,
        error: payload,
      };
    }
    case DISMISS_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    case CLEAR_PROJECT: {
      return projectInitialState;
    }
    case GET_PARTIAL_ALLOCATION_SUCCESS: {
      return {
        ...state,
        modifying: false,
      };
    }
    case GET_PARTIAL_ALLOCATION_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case UPDATE_ACTIVE_PARTIAL_ALLOCATION_START: {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case UPDATE_ACTIVE_PARTIAL_ALLOCATION_SUCCESS: {
      return {
        ...state,
        error: null,
      };
    }
    case UPDATE_ACTIVE_PARTIAL_ALLOCATION_FAILURE: {
      return {
        ...state,
        error: payload,
      };
    }
    case 'FILE_UPLOAD_START': {
      return {
        ...state,
        fileUploading: true,
      }
    }
    case 'FILE_UPLOAD_END': {
      return {
        ...state,
        fileUploading: false,
      }
    }
    default:
      return {...state};
  }
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Error from '@material-ui/icons/Error';
import './style.css';

function getModalStyle() {
  const top = 40;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${left}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  title: {
    color: 'red',
  },
});

class View extends Component {
  state = {
    open: true,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.dismissError();
  };

  render() {
    const { classes } = this.props;
    const errorMessage = this.props.errorMessage
      ? this.props.errorMessage
      : 'Please refresh your browser and try again. If the problem persists, please contact GovInvest support.';

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.open}
        onClose={this.handleClose}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <div className="conflict-modal-content">
            <div className="conflict-modal-error-icon">
              <Error />
            </div>
            <h1 className="conflict-modal-title">
              Oops! Something went wrong.
            </h1>
            <div className="conflict-modal-message">{errorMessage}</div>
          </div>
          <div className="conflict-modal-footer">
            <div
              onClick={() => {
                this.handleClose();
                window.location.reload();
              }}
              className="conflict-modal-refresh-button"
            >
              Refresh
            </div>
            <div
              onClick={this.handleClose}
              className="conflict-modal-dismiss-button"
            >
              Dismiss
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

View.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(View);

import { v4 as uuidv4 } from 'uuid';
import { DISMISS_ERROR } from '../../shared/common/constants';

import {
  ADD_PROPOSAL_FAILURE,
  ADD_PROPOSAL_START,
  ADD_PROPOSAL_SUCCESS,
  CALCULATE_PROPOSALS_FAILURE,
  CALCULATE_PROPOSALS_START,
  CALCULATE_PROPOSALS_START_QUEUED,
  CALCULATE_PROPOSALS_SUCCESS,
  CHANGE_ADJUSTMENT_VALUE,
  CHANGE_CENSUS_FILE,
  CHANGE_POOLED_CENSUS_FILE,
  CHANGE_HEALTHCARE_FILE,
  CHANGE_PARTIAL_ALLOCATIONS,
  CHANGE_PAY_GRADES,
  CHANGE_SALARY_SCHEDULE,
  CLEAR_WORKSPACE,
  DELETE_PROPOSAL_FAILURE,
  DELETE_PROPOSAL_START,
  DELETE_PROPOSAL_SUCCESS,
  DISCARD_PROPOSAL_CHANGES,
  GET_WORKSPACE_FAILURE,
  GET_WORKSPACE_START,
  GET_WORKSPACE_SUCCESS,
  RENAME_PROPOSAL_FAILURE,
  RENAME_PROPOSAL_START,
  RENAME_PROPOSAL_SUCCESS,
  UPDATE_PROPOSAL_FAILURE,
  UPDATE_PROPOSAL_START,
  UPDATE_PROPOSAL_SUCCESS,
} from './types';

import { CLEAR_SELECTED_PROPOSAL, SELECT_PROPOSAL } from './sidebar/types';

const workspaceInitialState = {
  counter: 0,
  loading: false,
  modifying: false,
  error: null,
  updatedToggle: true,
  deleting: false,
  lastUpdateType: '',
  workspace: {},
};

export const workspace = (state = workspaceInitialState, { type, payload }) => {
  switch (type) {
    case 'SEND_COMMAND_START': {
      return {
        ...state,
        modifying: true,
      };
    }
    case 'SEND_COMMAND_FAILURE': {
      return {
        ...state,
        modifying: false,
      };
    }
    case GET_WORKSPACE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_WORKSPACE_SUCCESS: {
      return {
        ...state,
        loading: false,
        modifying: false,
        deleting: false,
        error: null,
        updatedToggle: !state.updatedToggle,
        workspace: { ...state.workspace, ...payload },
      };
    }
    case GET_WORKSPACE_FAILURE: {
      return {
        ...state,
        loading: false,
        modifying: false,
        deleting: false,
        error: payload,
        workspace: {},
      };
    }
    case ADD_PROPOSAL_START: {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case ADD_PROPOSAL_SUCCESS: {
      return {
        ...state,
        modifying: false,
      };
    }
    case ADD_PROPOSAL_FAILURE: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: payload,
      };
    }
    case UPDATE_PROPOSAL_START: {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case UPDATE_PROPOSAL_SUCCESS: {
      return {
        ...state,
        modifying: false,
      };
    }
    case UPDATE_PROPOSAL_FAILURE: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: payload,
      };
    }
    case RENAME_PROPOSAL_START: {
      return {
        ...state,
        modifying: true,
      };
    }
    case RENAME_PROPOSAL_SUCCESS: {
      return {
        ...state,
        modifying: false,
      };
    }
    case RENAME_PROPOSAL_FAILURE: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: payload,
      };
    }
    case DELETE_PROPOSAL_START: {
      return {
        ...state,
        modifying: true,
        deleting: true,
      };
    }
    case DELETE_PROPOSAL_SUCCESS: {
      return {
        ...state,
        modifying: false,
      };
    }
    case DELETE_PROPOSAL_FAILURE: {
      return {
        ...state,
        loading: false,
        modifying: false,
        deleting: false,
        error: payload,
      };
    }
    case CLEAR_WORKSPACE: {
      return workspaceInitialState;
    }
    case DISMISS_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    case CHANGE_ADJUSTMENT_VALUE: {
      /**
       payload: {
         currentProposal: string,
         adjustmentId: string,
         values: number[] | undefined,
         table: {
           tableId: string,
           variableId: string,
         } | undefined,
         aggCostTableId: string | undefined,
         payGradeMinMax: { minIncrease: number, maxIncrease: number } | undefined
       }
       */
      const normalizedProposals = state.workspace.normalizedProposals;
      const proposal = normalizedProposals.byId[payload.currentProposal];
      const adjustments = proposal.adjustments;
      return {
        ...state,
        counter: state.counter + 1,
        lastUpdateType: 'adjustment',
        workspace: {
          ...state.workspace,
          normalizedProposals: {
            ...normalizedProposals,
            byId: {
              ...normalizedProposals.byId,
              [payload.currentProposal]: {
                ...proposal,
                adjustments: {
                  ...adjustments,
                  [payload.adjustmentId]: {
                    ...adjustments[payload.adjustmentId],
                    proposalValues: payload.values,
                    proposalTableId: payload.table && payload.table.tableId,
                    proposalVariableId: payload.table && payload.table.variableId,
                    proposalAggCostTableId: payload.aggCostTableId,
                    proposalPayGradeMinMax: payload.payGradeMinMax,
                    proposalPooledInput: payload.pooledInput,
                  },
                },
              },
            },
          },
        },
      };
    }
    case CHANGE_CENSUS_FILE: {
      return {
        ...state,
        workspace: {
          ...state.workspace,
          selectedCensusFileId: payload.censusFileId,
        },
      };
    }
    case CHANGE_POOLED_CENSUS_FILE: {
      return {
        ...state,
        workspace: {
          ...state.workspace,
          selectedPooledCensusFileId: payload.censusFileId,
        },
      };
    }
    case CHANGE_SALARY_SCHEDULE: {
      return {
        ...state,
        workspace: {
          ...state.workspace,
          selectedSalaryScheduleId: payload.salaryScheduleId,
        },
      };
    }
    case CHANGE_HEALTHCARE_FILE: {
      return {
        ...state,
        workspace: {
          ...state.workspace,
          selectedHealthcareId: payload.healthcareId,
        },
      };
    }
    case CHANGE_PAY_GRADES: {
      return {
        ...state,
        workspace: {
          ...state.workspace,
          selectedPayGradesId: payload.payGradesId,
        },
      };
    }
    case CHANGE_PARTIAL_ALLOCATIONS: {
      return {
        ...state,
        workspace: {
          ...state.workspace,
          selectedPartialAllocationsId: payload.partialAllocationsId,
        },
      };
    }
    case SELECT_PROPOSAL: {
      const normalizedProposals = state.workspace.normalizedProposals;
      const proposal = normalizedProposals.byId[payload];
      return {
        ...state,
        workspace: {
          ...state.workspace,
          currentProposalId: payload,
          selectedCensusFileId: proposal.censusFileId,
          selectedPooledCensusFileId: proposal.pooledCensusFileId,
          selectedSalaryScheduleId: proposal.salaryScheduleId,
          selectedHealthcareId: proposal.healthcareId,
          selectedPayGradesId: proposal.payGradesId,
          selectedPartialAllocationsId: proposal.partialAllocationsId,
        },
      };
    }
    case CLEAR_SELECTED_PROPOSAL: {
      return {
        ...state,
        workspace: {
          ...state.workspace,
          currentProposalId: undefined,
          selectedCensusFileId: undefined,
          selectedPooledCensusFileId: undefined,
          selectedSalaryScheduleId: undefined,
          selectedHealthcareId: undefined,
          selectedPayGradesId: undefined,
          selectedPartialAllocationsId: undefined,
        }
      };
    }
    case DISCARD_PROPOSAL_CHANGES: {
      const normalizedProposals = state.workspace.normalizedProposals;
      const proposal = normalizedProposals.byId[state.workspace.currentProposalId];
      return {
        ...state,
        workspace: {
          ...state.workspace,
          selectedCensusFileId: proposal.censusFileId,
          selectedPooledCensusFileId: proposal.pooledCensusFileId,
          selectedSalaryScheduleId: proposal.salaryScheduleId,
          selectedHealthcareId: proposal.healthcareId,
          selectedPayGradesId: proposal.payGradesId,
          selectedPartialAllocationsId: proposal.partialAllocationsId,
        },
      };
    }
    case 'CALCULATE_PROPOSALS_SUCCESS': {
      return {
        ...state,
        lastUpdateType: 'calculation',
      };
    }
    case 'ADD_PROPOSAL_TO_COMPARISON': {
      return {
        ...state,
        lastUpdateType: 'proposalAddedToComparison',
      };
    }
    case 'REMOVE_PROPOSAL_FROM_COMPARISON': {
      return {
        ...state,
        lastUpdateType: 'proposalRemovedFromComparison',
      };
    }
    case 'PUT_SELECTED_DEMOGRAPHIC_FILTERS': {
      return {
        ...state,
        workspace: {
          ...state.workspace,
          selectedDemographicFilters: payload,
        },
      };
    }
    case 'DEMOGRAPHIC_FILTERS_APPLIED': {
      return {
        ...state,
        workspace: {
          ...state.workspace,
          appliedDemographicFilters: JSON.parse(JSON.stringify(state.workspace.selectedDemographicFilters)),
        },
      };
    }
    default:
      return state;
  }
};

const calculationInitialState = {
  loading: false,
  modifying: false,
  error: null,
  calculationResults: {},
  calculationRequest: {},
  calculationCounter: 0,
  appliedDemographicFilters: {},
  demographics: [],
  clientId: uuidv4(),
  calcSequence: 0,
  calcSequenceReceived: 0,
  queuedRequest: null,
  activeRequest: false,
};

export const calculationResults = (
  state = calculationInitialState,
  { type, payload }
) => {
  switch (type) {
    case CALCULATE_PROPOSALS_START_QUEUED: {
      return {
        ...state,
        calculationCounter: state.calculationCounter + 1,
        modifying: true,
        calculationRequest: payload,
        calcSequence: state.calcSequence + 1,
        activeRequest: true,
        queuedRequest: null,
      };
    }
    case CALCULATE_PROPOSALS_START: {
      if (state.activeRequest) {
        return {
          ...state,
          queuedRequest: payload,
        };
      }
      return {
        ...state,
        calculationCounter: state.calculationCounter + 1,
        modifying: true,
        calculationRequest: payload,
        calcSequence: state.calcSequence + 1,
        activeRequest: true,
        errors: null,
      };
    }
    case CALCULATE_PROPOSALS_SUCCESS: {
      if (state.calcSequenceReceived >= payload.calcSequenceReceived) {
        return state;
      }
      return {
        ...state,
        calculationCounter: state.calculationCounter - 1,
        loading: false,
        modifying: false,
        error: null,
        errors: payload.errors,
        calculationResults: payload.charts,
        appliedDemographicFilters: payload.appliedDemographicFilters,
        demographics: payload.demographics,
        perfStats: payload.perfStats,
        calculationType: payload.calculationType,
        calcSequenceReceived: payload.calcSequence,
        activeRequest: false,
      };
    }
    case CALCULATE_PROPOSALS_FAILURE: {
      return {
        ...state,
        calculationCounter: state.calculationCounter - 1,
        loading: false,
        modifying: false,
        error: payload,
        calcSequenceReceived: payload.calcSequence,
        activeRequest: false,
      };
    }
    case 'CALCULATE_PROPOSALS_CLEAR': {
      return {
        ...state,
        calculationResults: {},
        appliedDemographicFilters: {},
        demographics: [],
      };
    }
    case 'UPDATE_PROPOSAL_SUCCESS': {
      const proposals = state.calculationRequest.proposals;
      const proposalsFilteredOutSaved = proposals.filter(
        proposal => !proposal.name.includes(' (saved)')
      );
      const modifiedCalculationRequest = {
        ...state.calculationRequest,
        proposals: proposalsFilteredOutSaved,
      };

      return {
        ...state,
        calculationRequest: modifiedCalculationRequest,
      };
    }
    default:
      return state;
  }
};

const progressInitialState = {
  percent: 0,
  uploading: false,
  validating: false,
  validated: false,
  error: null,
  response: {},
};

export const salaryScheduleUploadProgress = (
  state = progressInitialState,
  { type, payload }
) => {
  switch (type) {
    case 'GET_SALARY_SCHEDULE_SAS_TOKEN_START': {
      return {
        ...state,
        uploading: true,
        validating: false,
      };
    }
    case 'GET_SALARY_SCHEDULE_SAS_TOKEN_SUCCESS': {
      return { ...state };
    }
    case 'GET_SALARY_SCHEDULE_SAS_TOKEN_FAILURE': {
      return { ...state };
    }
    case 'UPLOAD_SALARY_SCHEDULE_START':
      return {
        ...state,
        uploading: true,
      };
    case 'UPLOAD_SALARY_SCHEDULE_SUCCESS':
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case 'UPLOAD_SALARY_SCHEDULE_FAILURE':
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case 'UPDATE_SALARY_SCHEDULE_UPLOAD_PROGRESS': {
      return {
        ...state,
        percent: payload,
      };
    }
    case 'UPDATE_SALARY_SCHEDULE_STATUS_START': {
      return {
        ...state,
        validating: true,
        validated: false,
        modifying: true,
        error: null,
      };
    }
    case 'UPDATE_SALARY_SCHEDULE_STATUS_SUCCESS': {
      return {
        ...state,
        modifying: false,
        error: null,
      };
    }
    case 'UPDATE_SALARY_SCHEDULE_STATUS_FAILURE': {
      return {
        ...state,
        validating: false,
        validated: false,
        modifying: false,
        error: payload,
      };
    }
    case 'GET_SALARY_SCHEDULE_BY_ID_START': {
      return {
        ...state,
        validating: true,
        validated: false,
        error: null,
      };
    }
    case 'GET_SALARY_SCHEDULE_BY_ID_SUCCESS': {
      return {
        ...state,
        validating: false,
        validated: true,
        error: false,
        response: payload,
      };
    }
    case 'GET_SALARY_SCHEDULE_BY_ID_INVALID': {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case 'GET_SALARY_SCHEDULE_BY_ID_FAILURE': {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case 'CLEAR_SALARY_SCHEDULE_UPLOAD_PROGRESS': {
      return progressInitialState;
    }
    default:
      return state;
  }
};

const salaryScheduleInitialState = {
  loading: false,
  modifying: false,
  error: null,
  salaryScheduleFiles: [],
};

export const salaryScheduleFiles = (
  state = salaryScheduleInitialState,
  { type, payload }
) => {
  switch (type) {
    case 'GET_SALARY_SCHEDULE_START': {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case 'GET_SALARY_SCHEDULE_SUCCESS': {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: null,
        salaryScheduleFiles: payload,
      };
    }
    case 'GET_SALARY_SCHEDULE_FAILURE': {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: payload,
      };
    }
    case 'UPDATE_ACTIVE_SALARY_SCHEDULE_START': {
      return {
        ...state,
        modifying: true,
      };
    }
    case 'UPDATE_ACTIVE_SALARY_SCHEDULE_SUCCESS': {
      return {
        ...state,
        modifying: false,
      };
    }
    case 'UPDATE_ACTIVE_SALARY_SCHEDULE_FAILURE': {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case 'DELETE_SALARY_SCHEDULE_START': {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case 'DELETE_SALARY_SCHEDULE_SUCCESS': {
      return {
        ...state,
        modifying: false,
        error: null,
      };
    }
    case 'DELETE_SALARY_SCHEDULE_FAILURE': {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    default:
      return state;
  }
};

export const salaryScheduleDataDictionary = (state = {}, { type, payload }) => {
  switch (type) {
    case 'GET_SALARY_SCHEDULE_DATA_DICTIONARY_SUCCESS': {
      return {
        ...payload,
      };
    }
    default:
      return state;
  }
};

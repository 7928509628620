import { DISMISS_ERROR } from '../../shared/common/constants';

import {
  CLEAR_TENANT,
  ADD_USER_TO_TENANT_START,
  ADD_USER_TO_TENANT_FAILURE,
  FETCH_TENANT_BY_ID_START,
  FETCH_TENANT_BY_ID_SUCCESS,
  FETCH_TENANT_BY_ID_FAILURE,
  DELETE_USER_FROM_TENANT_BY_EMAIL_SLUG_START,
  DELETE_USER_FROM_TENANT_BY_EMAIL_SLUG_SUCCESS,
  DELETE_USER_FROM_TENANT_BY_EMAIL_SLUG_FAILURE,
} from './types.js';

import {
  ADD_PROJECT_TO_TENANT_START,
  ADD_PROJECT_TO_TENANT_FAILURE,
  RESTORE_PROJECT_START,
  RESTORE_PROJECT_SUCCESS,
  RESTORE_PROJECT_FAILURE,
  DEACTIVATE_PROJECT_START,
  DEACTIVATE_PROJECT_SUCCESS,
  DEACTIVATE_PROJECT_FAILURE,
  DELETE_PROJECT_START,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  FETCH_PROJECTS_SUCCESS,
} from '../projects/types';

let tenantInitialState = {
  tenant: {},
  loading: false,
  modifying: false,
  error: null,
};

export const tenant = (state = tenantInitialState, { type, payload }) => {
  switch (type) {
    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        modifying: false,
        error: null,
      };
    case FETCH_TENANT_BY_ID_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_TENANT_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        tenant: payload,
        modifying: false,
        error: null,
      };
    }
    case FETCH_TENANT_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        tenant: {},
        modifying: false,
        error: payload,
      };
    }
    case ADD_USER_TO_TENANT_START: {
      return {
        ...state,
        addingUser: true,
        modifying: true,
      };
    }
    case ADD_USER_TO_TENANT_FAILURE: {
      return {
        ...state,
        addingUser: false,
        modifying: false,
        error: payload,
      };
    }
    case ADD_PROJECT_TO_TENANT_START: {
      return {
        ...state,
        addingProject: true,
        modifying: true,
      };
    }
    case ADD_PROJECT_TO_TENANT_FAILURE: {
      return {
        ...state,
        addingProject: false,
        modifying: false,
        error: payload,
      };
    }
    case DELETE_USER_FROM_TENANT_BY_EMAIL_SLUG_START: {
      return {
        ...state,
        modifying: true,
      };
    }
    case DELETE_USER_FROM_TENANT_BY_EMAIL_SLUG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case DELETE_USER_FROM_TENANT_BY_EMAIL_SLUG_FAILURE: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: payload,
      };
    }
    case DEACTIVATE_PROJECT_START: {
      return {
        ...state,
        modifying: true,
      };
    }
    case DEACTIVATE_PROJECT_SUCCESS: {
      return {
        ...state,
      };
    }
    case DEACTIVATE_PROJECT_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case RESTORE_PROJECT_START: {
      return {
        ...state,
        modifying: true,
      };
    }
    case RESTORE_PROJECT_SUCCESS: {
      return state;
    }
    case RESTORE_PROJECT_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case DELETE_PROJECT_START: {
      return {
        ...state,
        modifying: true,
      };
    }
    case DELETE_PROJECT_SUCCESS: {
      return state;
    }
    case DELETE_PROJECT_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case CLEAR_TENANT: {
      return tenantInitialState;
    }
    case DISMISS_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    case 'MODIFY_TENANT_START': {
      return {
        ...state,
        modifying: true,
      };
    }
    case 'MODIFY_TENANT_COMPLETE': {
      return {
        ...state,
        modifying: false,
      };
    }
    default:
      return state;
  }
};

import { SET_HEADER_MODE } from '../shared/common/constants';

export const fetchReceived = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_RECEIVED':
      return true;
    case 'FETCH_SENT':
      return false;
    default:
      return false;
  }
};

export const userRole = (state = {}, { type, payload }) => {
  switch (type) {
    case 'SET_USER_ROLE':
      return payload;
    default:
      return state;
  }
};

export const profile = (state = {}, { type, payload }) => {
  switch (type) {
    case 'SET_PROFILE':
      return payload ? payload : state;
    default:
      return state;
  }
};

export const loggedOut = (state = false, { type, payload }) => {
  switch (type) {
    case 'NO_ACCESS_TOKEN':
      return payload;
    default:
      return state;
  }
};

export const authorized = (state = null, { type, payload }) => {
  switch (type) {
    case 'AUTHORIZED':
      return payload;
    default:
      return state;
  }
};

export const username = (state = 'Demo User', { type, payload }) => {
  switch (type) {
    case 'SET_USER':
      return payload;
    default:
      return state;
  }
};

export const chartHoverIndex = (state = 0, { type, payload }) => {
  switch (type) {
    case 'HOVER_CHANGED': {
      return payload;
    }
    default:
      return state;
  }
};

export const management = (state = false, { type, payload }) => {
  switch (type) {
    case 'MOUNT_MANAGEMENT':
      return payload;
    default:
      return state;
  }
};

export const extended = (state = false, { type, payload }) => {
  switch (type) {
    case 'EXTEND_DASH':
      return !state;
    default:
      return state;
  }
};

export const chartType = (state = 'line', { type, payload }) => {
  switch (type) {
    case 'SELECT_CHART_TYPE':
      return payload;
    default:
      return state;
  }
};

export const activeChartName = (
  state = 'Total Cost (Annual)',
  { type, payload }
) => {
  switch (type) {
    case 'SELECT_ACTIVE_CHART':
      return payload;
    default:
      return state;
  }
};

export const chartDropdownOneIndex = (state = 0, { type, payload }) => {
  switch (type) {
    case 'SELECT_CHART_DROPDOWN_ONE_INDEX':
      return payload;
    default:
      return state;
  }
};

export const chartDropdownTwoIndex = (state = 0, { type, payload }) => {
  switch (type) {
    case 'SELECT_CHART_DROPDOWN_TWO_INDEX':
      return payload;
    default:
      return state;
  }
};

const uiInitialState = {
  headerMode: '',
  topChartOpen: true,
};

export const UI = (state = uiInitialState, { type, payload }) => {
  switch (type) {
    case SET_HEADER_MODE: {
      return {
        ...state,
        headerMode: payload,
      };
    }
    case 'TOGGLE_TOP_CHART_COLLAPSE': {
      return {
        ...state,
        topChartOpen: !state.topChartOpen,
      };
    }
    default:
      return state;
  }
};

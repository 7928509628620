import React from 'react';
import styled from 'styled-components';
import ChartCap from '../main-chart/chart-cap';
import TableChart from '../main-chart/table-chart';
import CostLineChart from '../main-chart/line-chart';
import CostBarChart from '../main-chart/bar-chart.js';
import CostHorizontalBarChart from '../main-chart/h-bar-chart';
import govInvestLogo from '../../assets/govinvest_logo_no_subtitle_small.png';
import PrintableContext from './common/printable-context';
import './style.css';

const ProjectTitle = styled.h2`
  display: inline;
  color: #666666;
  font-size: 16px !important;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 2.5px;
`;

const ReportTitle = styled.h1`
  text-align: center;
  color: #555555;
  font-size: 24px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: ${props => (props.chartType === 'table' ? '30px' : '5px')};
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 45px;
`;

const ReportNoteWrapper = styled.div`
  display: flex;
  align-items: center;
  & img {
    max-height: 30px !important;
  }
`;

const ReportNoteText = styled.div`
  font-size: 11px;
  color: #555555;
  white-space: nowrap;
`;

const StyledImage = styled.img`
  max-height: 40px;
  margin-left: 10px;
`;

const View = props => {
  const {
    workspace,
    chartData,
    chartProperties,
    calculationResults,
    selectedCensusFileId,
    selectedPooledCensusFileId,
    selectedSalaryScheduleId,
    selectedHealthcareId,
    selectedPayGradesId,
    selectedPartialAllocationsId,
    calculationType,
    demographics,
  } = props.printData;
  const {
    chartType,
    activeChartName,
    chartDropdownOneIndex,
    proposalIsChanged,
    currentProposalName,
    comparedProposalsNames,
    chartDropdownTwoIndex,
    currentProposalImpactName,
    chartScale,
    chartDivisor,
  } = chartProperties;

  const chartKey = Reflect.ownKeys(
    calculationResults.chartData[activeChartName]
  )[chartDropdownOneIndex];

  const currentDemographic = () => {
    const demographicName = Reflect.ownKeys(
      calculationResults.chartData[activeChartName]
    )[chartDropdownOneIndex];
    const demographicYear = Reflect.ownKeys(
      calculationResults.chartData[activeChartName][demographicName]
    )[chartDropdownTwoIndex];
    const result = {
      year: demographicYear,
      name: demographicName,
    };
    return result;
  };

  let title = activeChartName;
  if (activeChartName === 'Demographic Detail (Annual)') {
    title =
      'Costs by ' +
      currentDemographic().name +
      ' for ' +
      currentDemographic().year;
  }
  if (activeChartName === 'Cost Category Detail (Annual)') {
    title = 'Annual Costs for Cost Category: ' + chartKey;
  }
  if (activeChartName === 'Cost Detail (Annual)') {
    title = 'Annual Costs for Cost: ' + chartKey;
  }
  if (activeChartName === 'Demographic Detail (Cumulative)') {
    title =
      'Total Cost by ' +
      currentDemographic().name +
      ' through ' +
      currentDemographic().year;
  }

  return (
    <div className="print-root" id="print-root">
      <ProjectTitle>{workspace.projectName}</ProjectTitle>

      <ReportTitle className="print-proposal-chart-title" chartType={chartType}>
        {currentProposalName} - {title}
      </ReportTitle>
      {chartType !== 'table' && (
        <div>
          <ChartCap
            chartData={chartData}
            hideValues={chartType === 'hbar'}
            chartProperties={chartProperties}
            selectedYearIndex={chartData.length - 1}
            contractStartDate={workspace.contractStartDate}
            currentProposalImpactName={currentProposalImpactName}
            printView={true}
          />
        </div>
      )}
      <div className="print-chart">
        {chartType === 'line' && (
          <CostLineChart
            chartData={chartData}
            currentProposalName={currentProposalName}
            comparedProposalsNames={comparedProposalsNames}
            currentProposalImpactName={currentProposalImpactName}
            proposalIsChanged={proposalIsChanged}
            showComparisons={() => {}}
            disableAnimation={true}
            disableToolTips={true}
            proposalNames={calculationResults.proposals}
            chartScale={chartScale}
            chartDivisor={chartDivisor}
            selectedYearIndex={chartData.length - 1}
            printView={true}
            contractStartDate={workspace.contractStartDate}
          />
        )}
        {chartType === 'bar' && (
          <CostBarChart
            chartData={chartData}
            currentProposalName={currentProposalName}
            comparedProposalsNames={comparedProposalsNames}
            currentProposalImpactName={currentProposalImpactName}
            proposalIsChanged={proposalIsChanged}
            showComparisons={() => {}}
            disableAnimation={true}
            disableToolTips={true}
            proposalNames={calculationResults.proposals}
            chartScale={chartScale}
            chartDivisor={chartDivisor}
            contractStartDate={workspace.contractStartDate}
          />
        )}
        {chartType === 'hbar' && (
          <CostHorizontalBarChart
            chartData={chartData}
            proposalName={currentProposalName}
            currentProposalName={currentProposalName}
            comparedProposalsNames={comparedProposalsNames}
            currentProposalImpactName={currentProposalImpactName}
            proposalIsChanged={proposalIsChanged}
            showComparisons={() => {}}
            disableAnimation={true}
            disableToolTips={true}
            proposalNames={calculationResults.proposals}
            chartScale={chartScale}
            chartDivisor={chartDivisor}
          />
        )}
        {chartType === 'table' && (
          <TableChart
            chartData={chartData}
            currentProposalImpactName={
              chartProperties.currentProposalImpactName
            }
            printView={true}
          />
        )}
        <PrintableContext
          workspace={workspace}
          currentProposalName={currentProposalName}
          selectedCensusFileId={selectedCensusFileId}
          selectedPooledCensusFileId={selectedPooledCensusFileId}
          selectedSalaryScheduleId={selectedSalaryScheduleId}
          selectedHealthcareId={selectedHealthcareId}
          selectedPayGradesId={selectedPayGradesId}
          selectedPartialAllocationsId={selectedPartialAllocationsId}
          calculationType={calculationType}
          demographics={demographics}
        />
        <FooterWrapper>
          <div className="print-copyright">
            Confidential &copy; 2021 GovInvest, Inc.
          </div>
          <ReportNoteWrapper>
            <ReportNoteText>This report produced using</ReportNoteText>
            <StyledImage
              src={govInvestLogo}
              alt="govinvest logo"
            />
          </ReportNoteWrapper>
        </FooterWrapper>
      </div>
    </div>
  );
};

export default View;

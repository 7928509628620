import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import govInvestLogo from '../../assets/govinvest_logo_no_subtitle_small.png';
import PrintableContext from './common/printable-context';
import './style.css';

const ProjectTitle = styled.h1`
  display: inline;
  color: #555555;
  font-weight: 400;
  font-size: 16px !important;
  white-space: nowrap;
  margin-bottom: 2.5px;
`;

const ReportTitle = styled.h1`
  text-align: center;
  color: #555555;
  font-size: 24px;
  font-weight: 400;
  margin: 30px 0;
`;

const StyledAdjustmentName = styled.div`
  font-size: ${props => (props.minify === 'true' ? '11px' : '12px')} !important;
  text-align: left;
  color: #555555;
  font-weight: 600;
`;

const StyledProposalName = styled.div`
  font-size: ${props => (props.minify === 'true' ? '11px' : '12px')} !important;
  padding-left: 10px;
  color #555555;
`;

const StyledValue = styled.div`
  max-width: max-content;
  padding-left: 5px;
  background: ${props => (props.difference ? '#f8ff00' : '')};
  justify-self: flex-end;
`;

const DollarPctLabel = styled.span`
  font-size: 10px;
  font-weight: 400;
  white-space: nowrap;
`;

const StyledHeaderCellAdjustment = styled(TableCell)`
  padding-left: ${props => (props.minify === 'true' ? '5px' : '')} !important;
`;

const StyledTableCell = styled(TableCell)`
  font-size: ${props => (props.minify === 'true' ? '11px' : '12px')} !important;
  padding-left: 5px !important;
  padding-right: ${props => (props.minify === 'true' ? '5px' : '')};
`;

const StyledTableCellAdjustment = styled(StyledTableCell)`
  padding-left: ${props =>
    props.minify === 'true' ? '5px' : '16px'} !important;
`;

const StyledValueWrapper = styled.div`
  display: grid;
  justify-content: flex-end;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 45px;
`;

const ReportNoteWrapper = styled.div`
  display: flex;
  align-items: center;
  & img {
    max-height: 30px !important;
  }
`;

const ReportNoteText = styled.div`
  font-size: 11px;
  color: #555555;
  white-space: nowrap;
`;

const StyledImage = styled.img`
  max-height: 40px;
  margin-left: 10px;
`;

const TableNoteStyled = styled.div`
  font-size: 12px;
  margin: 0 0 0 5px;
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    boxShadow: 'none',
  },
  tableCell: {
    padding: '5px',
    paddingRight: '0px',
    color: '#555555',
    verticalAlign: 'bottom',
  },

  tableHeaderCell: {
    color: 'white',
    paddingRight: '0px',
    background: '#3e4156',
  },
}));

const AdjustmentsTable = props => {
  const {
    workspace,
    currentProposal,
    chartProperties,
    calculationResults,
    chartTitle,
    selectedCensusFileId,
    selectedPooledCensusFileId,
    selectedSalaryScheduleId,
    selectedHealthcareId,
    selectedPayGradesId,
    selectedPartialAllocationsId,
    calculationType,
    demographics,
  } = props.printData;
  const { currentProposalName } = chartProperties;

  const sortedAdjustments = workspace.adjustments.sort((a, b) => {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  });

  const adjustmentTableRows = [];

  const currentNormalizedProposal =
    workspace.normalizedProposals.byId[currentProposal.proposalId];

  sortedAdjustments.forEach(adj => {
    const currentProposalAdjustment =
      currentNormalizedProposal.adjustments[adj.adjustmentId];
    let adjustmentValues = adj.baseline;
    if (currentProposalAdjustment) {
      adjustmentValues = currentProposalAdjustment.proposalValues;
    }
    const frequencyMapping = {
      Annual: 'per year',
      Hourly: 'per hour',
      Weekly: 'per week',
      'Bi-Weekly': 'bi-weekly',
      'Semi-Monthly': 'semi-monthly',
      Monthly: 'per month',
    };
    const frequency = frequencyMapping[adj.frequency];
    let unit = '%';
    if (adj.dollarPct === 'Dollar') {
      unit = '$';
    }

    adjustmentTableRows.push({
      name: adj.name,
      baseline: adj.baseline,
      proposalValues: adjustmentValues,
      unit: unit,
      frequency: frequency,
      inputType: adj.inputType,
      controlType: adj.controlType,
    });
  });

  const generateValue = (unit, value) => {
    const result =
      unit === '$'
        ? `${unit}${parseFloat(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${parseFloat(value).toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          })}${unit}`;
    return result;
  };

  const classes = useStyles();
  const minify = calculationResults.chartYears.length >= 8 ? 'true' : 'false';
  const adjustmentRowsTablesFilteredOut = adjustmentTableRows.filter(row => row.controlType === 'values');
  const tableHasInputAdjustments = adjustmentRowsTablesFilteredOut.length > 0;

  //TODO: what to do with non-values inputs...?

  return (
    <div className="print-root">
      <ProjectTitle>{workspace.projectName}</ProjectTitle>
      <ReportTitle>{currentProposalName} - {chartTitle}</ReportTitle>
      <Paper className={classes.root}>
        {tableHasInputAdjustments &&
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableRow}>
                <StyledHeaderCellAdjustment
                  className={classes.tableHeaderCell}
                  minify={minify}
                >
                  Adjustment
                </StyledHeaderCellAdjustment>
                {calculationResults.chartYears.map(year => (
                  <TableCell
                    key={shortid.generate()}
                    className={classes.tableHeaderCell}
                    align="right"
                  >
                    {year}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {adjustmentRowsTablesFilteredOut.map((row, rowIndex) => (
                <TableRow key={shortid.generate()} className={classes.tableRow}>
                  <StyledTableCellAdjustment
                    className={classes.tableCell}
                    minify={minify}
                  >
                    <StyledAdjustmentName minify={minify}>
                      {row.name}
                      <DollarPctLabel>
                        {row.frequency && ` - ${row.frequency}`}
                      </DollarPctLabel>
                    </StyledAdjustmentName>
                    <StyledProposalName minify={minify}>
                      Baseline
                    </StyledProposalName>
                    <StyledProposalName minify={minify}>
                      {currentProposalName}
                    </StyledProposalName>
                  </StyledTableCellAdjustment>
                  {row.baseline.map((value, valueIndex) => (
                    <StyledTableCell
                      key={shortid.generate()}
                      className={classes.tableCell}
                      minify={minify}
                      align="right"
                    >
                      <StyledValueWrapper>
                        <StyledValue>
                          {generateValue(row.unit, value)}
                        </StyledValue>
                        <StyledValue
                          difference={value !== row.proposalValues[valueIndex]}
                        >
                          {generateValue(
                            row.unit,
                            row.proposalValues[valueIndex]
                          )}
                        </StyledValue>
                      </StyledValueWrapper>
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
        <TableNoteStyled>Costs that use table input or aggregate cost tables are not shown.  Additional pay grade increases are also not shown.</TableNoteStyled>
      </Paper>
      <PrintableContext
        workspace={workspace}
        currentProposalName={currentProposalName}
        selectedCensusFileId={selectedCensusFileId}
        selectedPooledCensusFileId={selectedPooledCensusFileId}
        selectedSalaryScheduleId={selectedSalaryScheduleId}
        selectedHealthcareId={selectedHealthcareId}
        selectedPayGradesId={selectedPayGradesId}
        selectedPartialAllocationsId={selectedPartialAllocationsId}
        calculationType={calculationType}
        demographics={demographics}
      />
      <FooterWrapper >
        <div className="print-copyright">
          Confidential &copy; 2021 GovInvest, Inc.
        </div>
        <ReportNoteWrapper>
          <ReportNoteText>This report produced using</ReportNoteText>
          <StyledImage
            src={govInvestLogo}
            alt="govinvest logo"
          />
        </ReportNoteWrapper>
      </FooterWrapper>
    </div>
  );
};

export default AdjustmentsTable;

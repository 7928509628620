const progressInitialState = {
  percent: 0,
  uploading: false,
  validating: false,
  validated: false,
  error: null,
  response: {},
};

export const payGradesUploadProgress = (
  state = progressInitialState,
  { type, payload }
) => {
  switch (type) {
    case 'GET_PAY_GRADES_SAS_TOKEN_START': {
      return {
        ...state,
        uploading: true,
        validating: false,
      };
    }
    case 'GET_PAY_GRADES_SAS_TOKEN_SUCCESS': {
      return { ...state };
    }
    case 'GET_PAY_GRADES_SAS_TOKEN_FAILURE': {
      return { ...state };
    }
    case 'UPLOAD_PAY_GRADES_START':
      return {
        ...state,
        uploading: true,
      };
    case 'UPLOAD_PAY_GRADES_SUCCESS':
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case 'UPLOAD_PAY_GRADES_FAILURE':
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case 'UPDATE_PAY_GRADES_UPLOAD_PROGRESS': {
      return {
        ...state,
        percent: payload,
      };
    }
    case 'UPDATE_PAY_GRADES_STATUS_START': {
      return {
        ...state,
        validating: true,
        validated: false,
        modifying: true,
        error: null,
      };
    }
    case 'UPDATE_PAY_GRADES_STATUS_SUCCESS': {
      return {
        ...state,
        modifying: false,
        error: null,
      };
    }
    case 'UPDATE_PAY_GRADES_STATUS_FAILURE': {
      return {
        ...state,
        validating: false,
        validated: false,
        modifying: false,
        error: payload,
      };
    }
    case 'GET_PAY_GRADES_BY_ID_START': {
      return {
        ...state,
        validating: true,
        validated: false,
        error: null,
      };
    }
    case 'GET_PAY_GRADES_BY_ID_SUCCESS': {
      return {
        ...state,
        validating: false,
        validated: true,
        error: false,
        response: payload,
      };
    }
    case 'GET_PAY_GRADES_BY_ID_INVALID': {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case 'GET_PAY_GRADES_BY_ID_FAILURE': {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case 'CLEAR_PAY_GRADES_UPLOAD_PROGRESS': {
      return progressInitialState;
    }
    default:
      return state;
  }
};

const payGradesInitialState = {
  loading: false,
  modifying: false,
  error: null,
  payGradesFiles: [],
};

export const payGradesFiles = (
  state = payGradesInitialState,
  { type, payload }
) => {
  switch (type) {
    case 'GET_PAY_GRADES_START': {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case 'GET_PAY_GRADES_SUCCESS': {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: null,
        payGradesFiles: payload,
      };
    }
    case 'GET_PAY_GRADES_FAILURE': {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: payload,
      };
    }
    case 'UPDATE_ACTIVE_PAY_GRADES_START': {
      return {
        ...state,
        modifying: true,
      };
    }
    case 'UPDATE_ACTIVE_PAY_GRADES_SUCCESS': {
      return {
        ...state,
        modifying: false,
      };
    }
    case 'UPDATE_ACTIVE_PAY_GRADES_FAILURE': {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case 'DELETE_PAY_GRADES_START': {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case 'DELETE_PAY_GRADES_SUCCESS': {
      return {
        ...state,
        modifying: false,
        error: null,
      };
    }
    case 'DELETE_PAY_GRADES_FAILURE': {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    default:
      return state;
  }
};

export const payGradesDataDictionary = (state = {}, { type, payload }) => {
  switch (type) {
    case 'GET_PAY_GRADES_DATA_DICTIONARY_SUCCESS': {
      return {
        ...payload,
      };
    }
    default:
      return state;
  }
};

import React, { useEffect } from 'react';
import LZString from 'lz-string';
import CostByYearTable from './cost-by-year-table';
import AdjustmentsTable from './adjustments-table';
import View from './view';

function Printable() {
  useEffect(() => {
    document.title = 'GovInvest Labor Costing Module';
  });

  const urlParams = new URLSearchParams(window.location.search);
  const printId = urlParams.get('print-id');
  const serializedPrintData = LZString.decompress(localStorage.getItem('print-data'));

  if (!serializedPrintData) {
    return <div>Error: no print data</div>;
  }

  const printDataContainer = JSON.parse(serializedPrintData);
  const printData = printDataContainer[printId];

  if (printData.chartOverride === 'costByYearTable' ||
      printData.chartOverride === 'differenceFromBaselineTable') {
        return <CostByYearTable printData={printData} />;
  }
  if (printData.chartOverride === 'adjustmentsTable') {
    return <AdjustmentsTable printData={printData} />;
  }
  return <View printData={printData} />;
}

export default Printable;

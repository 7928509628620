import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import InternetExplorerWarning from './internet-explorer-warning';
import * as serviceWorker from './serviceWorker';
import Routes from "./routes";

const browserIsIE = () =>
  !!navigator.userAgent.match(/Trident/g) ||
  !!navigator.userAgent.match(/MSIE/g);

const componentToRender = () =>
  browserIsIE() ? <InternetExplorerWarning /> : <Routes />;

ReactDOM.render(componentToRender(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
export { copyAdjustmentsFromWorkspace } from './adjustmentHelper';
export { mapUnsavedValuesForProposalCalc } from './adjustmentHelper';
export { mapSavedValuesForProposalCalc } from './adjustmentHelper';

import React, { useEffect } from 'react';
import Loadable from '@docusaurus/react-loadable';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Auth from './auth/auth';
import { getErrors } from './selectors';
import Header from './components/header';
import Reroute from './components/reroute';
import * as actions from './components/user/actions.js';
import ErrorModal from './components/common/error-modal';
import { getProfile } from './components/profile/selectors';
import NotificationControls from './components/notification-controls';
import { fetchUserTenants } from './components/user/actions.js';

import {
  getUser,
  getUserTenants,
  getUserFetched,
  getUserTenantsFetched,
  getUserTenantsLoading,
} from './components/user/selectors';

import './app.css';

const Loading = () => <div />;

const Workspace = Loadable({
  loader: () => import('./components/workspace'),
  loading: Loading,
});

const ProjectSetup = Loadable({
  loader: () => import('./components/project-setup'),
  loading: Loading,
});

const Tenants = Loadable({
  loader: () => import('./components/tenants'),
  loading: Loading,
});
const Projects = Loadable({
  loader: () => import('./components/projects'),
  loading: Loading,
});

const Profile = Loadable({
  loader: () => import('./components/profile'),
  loading: Loading,
});

const AppInfo = Loadable({
  loader: () => import('./components/app-info'),
  loading: Loading,
});

const notFound = () => <div>Page not found</div>;

const App = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = new Auth();
    if(!auth.getRenewScheduled()) {
      auth.scheduleRenewalWithRandom();
    } else {
      console.log('Renew already scheduled')
    }
    const userProfile = auth.getProfile();
    if(userProfile.requiresPasswordReset) {
      auth.changePassword(true);
    }
    props.setProfile(userProfile);
    dispatch(fetchUserTenants(userProfile.userId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (props.auth.isAccessTokenValid() === false) {
    console.log('invalid access token.  waiting for token to be valid');
    return <div></div>;
  }
  const ErrorModalComponent = () => {
    if (props.error && props.error.code === 409) {
      return (
        <ErrorModal errorMessage="Another user may be editing this project. Multiple users are not able to edit the same project at the same time." />
      );
    }
    if (props.error) {
      return <ErrorModal />;
    }
  };
  return (
    <div className="app-container">
      <Header auth={props.auth} />
      {ErrorModalComponent()}
      <Switch>
        <Route exact path="/app">
          <Reroute />
        </Route>
        <Route exact path="/app/admin/tenants" component={Tenants} />
        <Route exact path="/app/admin/app-info" component={AppInfo} />
        <Route exact path="/app/admin/notification-controls" component={NotificationControls} />
        <Route exact path="/app/tenants/:tenantId/projects" component={Projects} />
        <Route path="/app/tenants/:tenantId/projects/:projectId/setup" component={ProjectSetup} />
        <Route
          exact
          path="/app/tenants/:tenantId/projects/:projectId/workspace"
          component={Workspace}
        />
        <Route exact path="/app/my-profile" render={() => <Profile auth={props.auth} />} />
        <Route exact path="*" component={notFound} />
      </Switch>
    </div>
  );
};

const mapStateToProps = state => ({
  user: getUser(state),
  error: getErrors(state),
  profile: getProfile(state),
  loggedOut: state.loggedOut,
  userTenants: getUserTenants(state),
  userFetched: getUserFetched(state),
  userTenantsFetched: getUserTenantsFetched(state),
  userTenantsLoading: getUserTenantsLoading(state),
});

export default connect(mapStateToProps, actions)(App);

import * as types from './types';

const accountingMatrixProgressInitialState = {
  percent: 0,
  uploading: false,
  validating: false,
  validated: false,
  error: null,
  response: {},
};

export const accountingMatrixUploadProgress = (
  state = accountingMatrixProgressInitialState,
  { type, payload }
) => {
  switch (type) {
    case types.GET_ACCOUNTING_MATRIX_SAS_TOKEN_START: {
      return {
        ...state,
        uploading: true,
        validating: false,
      };
    }
    case types.GET_ACCOUNTING_MATRIX_SAS_TOKEN_SUCCESS: {
      return { ...state };
    }
    case types.GET_ACCOUNTING_MATRIX_SAS_TOKEN_FAILURE: {
      return { ...state };
    }
    case types.UPLOAD_ACCOUNTING_MATRIX_START:
      return {
        ...state,
        uploading: true,
      };
    case types.UPLOAD_ACCOUNTING_MATRIX_SUCCESS:
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case types.UPLOAD_ACCOUNTING_MATRIX_FAILURE:
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case types.UPDATE_ACCOUNTING_MATRIX_UPLOAD_PROGRESS: {
      return {
        ...state,
        percent: payload,
      };
    }
    case types.UPDATE_ACCOUNTING_MATRIX_STATUS_START: {
      return {
        ...state,
        validating: true,
        validated: false,
        modifying: true,
        error: null,
      };
    }
    case types.UPDATE_ACCOUNTING_MATRIX_STATUS_SUCCESS: {
      return {
        ...state,
        modifying: false,
        error: null,
      };
    }
    case types.UPDATE_ACCOUNTING_MATRIX_STATUS_FAILURE: {
      return {
        ...state,
        validating: false,
        validated: false,
        modifying: false,
        error: payload,
      };
    }
    case types.GET_ACCOUNTING_MATRIX_BY_ID_START: {
      return {
        ...state,
        validating: true,
        validated: false,
        error: null,
      };
    }
    case types.GET_ACCOUNTING_MATRIX_BY_ID_SUCCESS: {
      return {
        ...state,
        validating: false,
        validated: true,
        error: false,
        response: payload,
      };
    }
    case types.GET_ACCOUNTING_MATRIX_BY_ID_INVALID: {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case types.GET_ACCOUNTING_MATRIX_BY_ID_FAILURE: {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case types.CLEAR_ACCOUNTING_MATRIX_UPLOAD_PROGRESS: {
      return accountingMatrixProgressInitialState;
    }
    default:
      return state;
  }
};

const accountingMatrixInitialState = {
  loading: false,
  modifying: false,
  error: null,
  accountingMatrixFiles: [],
};

export const accountingMatrixFiles = (
  state = accountingMatrixInitialState,
  { type, payload }
) => {
  switch (type) {
    case types.GET_ACCOUNTING_MATRIX_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case types.GET_ACCOUNTING_MATRIX_SUCCESS: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: null,
        accountingMatrixFiles: payload,
      };
    }
    case types.GET_ACCOUNTING_MATRIX_FAILURE: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: payload,
      };
    }
    case types.UPDATE_ACTIVE_ACCOUNTING_MATRIX_START: {
      return {
        ...state,
        modifying: true,
      };
    }
    case types.UPDATE_ACTIVE_ACCOUNTING_MATRIX_SUCCESS: {
      return {
        ...state,
        modifying: false,
      };
    }
    case types.UPDATE_ACTIVE_ACCOUNTING_MATRIX_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case types.DELETE_ACCOUNTING_MATRIX_START: {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case types.DELETE_ACCOUNTING_MATRIX_SUCCESS: {
      return {
        ...state,
        modifying: false,
        error: null,
      };
    }
    case types.DELETE_ACCOUNTING_MATRIX_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    default:
      return state;
  }
};

export const accountingMatrixDataDictionary = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case types.GET_ACCOUNTING_MATRIX_DATA_DICTIONARY_SUCCESS: {
      return {
        ...payload,
      };
    }
    default:
      return state;
  }
};





const partialAllocationProgressInitialState = {
  percent: 0,
  uploading: false,
  validating: false,
  validated: false,
  error: null,
  response: {},
};

export const partialAllocationUploadProgress = (
  state = partialAllocationProgressInitialState,
  { type, payload }
) => {
  switch (type) {
    case types.GET_PARTIAL_ALLOCATION_SAS_TOKEN_START: {
      return {
        ...state,
        uploading: true,
        validating: false,
      };
    }
    case types.GET_PARTIAL_ALLOCATION_SAS_TOKEN_SUCCESS: {
      return { ...state };
    }
    case types.GET_PARTIAL_ALLOCATION_SAS_TOKEN_FAILURE: {
      return { ...state };
    }
    case types.UPLOAD_PARTIAL_ALLOCATION_START:
      return {
        ...state,
        uploading: true,
      };
    case types.UPLOAD_PARTIAL_ALLOCATION_SUCCESS:
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case types.UPLOAD_PARTIAL_ALLOCATION_FAILURE:
      return {
        ...state,
        uploading: false,
        payload: payload,
      };
    case types.UPDATE_PARTIAL_ALLOCATION_UPLOAD_PROGRESS: {
      return {
        ...state,
        percent: payload,
      };
    }
    case types.UPDATE_PARTIAL_ALLOCATION_STATUS_START: {
      return {
        ...state,
        validating: true,
        validated: false,
        modifying: true,
        error: null,
      };
    }
    case types.UPDATE_PARTIAL_ALLOCATION_STATUS_SUCCESS: {
      return {
        ...state,
        modifying: false,
        error: null,
      };
    }
    case types.UPDATE_PARTIAL_ALLOCATION_STATUS_FAILURE: {
      return {
        ...state,
        validating: false,
        validated: false,
        modifying: false,
        error: payload,
      };
    }
    case types.GET_PARTIAL_ALLOCATION_BY_ID_START: {
      return {
        ...state,
        validating: true,
        validated: false,
        error: null,
      };
    }
    case types.GET_PARTIAL_ALLOCATION_BY_ID_SUCCESS: {
      return {
        ...state,
        validating: false,
        validated: true,
        error: false,
        response: payload,
      };
    }
    case types.GET_PARTIAL_ALLOCATION_BY_ID_INVALID: {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case types.GET_PARTIAL_ALLOCATION_BY_ID_FAILURE: {
      return {
        ...state,
        validating: false,
        validated: false,
        error: payload,
        response: payload,
      };
    }
    case types.CLEAR_PARTIAL_ALLOCATION_UPLOAD_PROGRESS: {
      return partialAllocationProgressInitialState;
    }
    default:
      return state;
  }
};

const partialAllocationInitialState = {
  loading: false,
  modifying: false,
  error: null,
  partialAllocationFiles: [],
};

export const partialAllocationFiles = (
  state = partialAllocationInitialState,
  { type, payload }
) => {
  switch (type) {
    case types.GET_PARTIAL_ALLOCATION_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case types.GET_PARTIAL_ALLOCATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: null,
        partialAllocationFiles: payload,
      };
    }
    case types.GET_PARTIAL_ALLOCATION_FAILURE: {
      return {
        ...state,
        loading: false,
        modifying: false,
        error: payload,
      };
    }
    case types.UPDATE_ACTIVE_PARTIAL_ALLOCATION_START: {
      return {
        ...state,
        modifying: true,
      };
    }
    case types.UPDATE_ACTIVE_PARTIAL_ALLOCATION_SUCCESS: {
      return {
        ...state,
        modifying: false,
      };
    }
    case types.UPDATE_ACTIVE_PARTIAL_ALLOCATION_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    case types.DELETE_PARTIAL_ALLOCATION_START: {
      return {
        ...state,
        modifying: true,
        error: null,
      };
    }
    case types.DELETE_PARTIAL_ALLOCATION_SUCCESS: {
      return {
        ...state,
        modifying: false,
        error: null,
      };
    }
    case types.DELETE_PARTIAL_ALLOCATION_FAILURE: {
      return {
        ...state,
        modifying: false,
        error: payload,
      };
    }
    default:
      return state;
  }
};

export const partialAllocationDataDictionary = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case types.GET_PARTIAL_ALLOCATION_DATA_DICTIONARY_SUCCESS: {
      return {
        ...payload,
      };
    }
    default:
      return state;
  }
};

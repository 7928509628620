import { put, take, fork, select, call, cancel, cancelled, takeEvery } from 'redux-saga/effects';
import Auth from '../../auth/auth';
import * as config from '../../lib/config';

export function* handleCalculation(action) {
  const bgCalculationTask = yield fork(() => handleCalculationStart(action));
  yield take('CLEAR_WORKSPACE');
  yield cancel(bgCalculationTask);
}

export function* handleCalculationStart(action) {
  const calculationResults = yield select(getCalculationResults);
  let responseBody;

  if (calculationResults.queuedRequest) {
    return;
  }

  new Auth().checkAuth();
  let result = yield call(fetch, `${config.baseURL()}/api/calculations`, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(action.payload),
  });

  responseBody = yield result.json();
  try {
    while (responseBody.continuationToken) {
      result = yield call(fetch, `${config.baseURL()}/api/calculations`, {
        method: 'POST',
        credentials: 'include',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          ...action.payload,
          continuationToken: responseBody.continuationToken,
        }),
      });
      responseBody = yield result.json();
    }
  } finally {
    if (yield cancelled()) {
      yield put({ type: 'CALCULATE_PROPOSALS_FAILURE', payload: result });
    }

    if (!result.ok) {
      yield put({ type: 'CALCULATE_PROPOSALS_FAILURE', payload: result });
    } else {
      yield put({ type: 'CALCULATE_PROPOSALS_SUCCESS', payload: responseBody });
    }
  }
}

export function* handleCalculationSuccess() {
  const calculationResults = yield select(getCalculationResults);
  if (calculationResults.queuedRequest) {
    yield put({
      type: 'CALCULATE_PROPOSALS_START_QUEUED',
      payload: calculationResults.queuedRequest,
    });
  }
}

function getCalculationResults(state) {
  return state.calculationResults;
}

export function* watchCalculationsSaga() {
  yield takeEvery('CALCULATE_PROPOSALS_START', handleCalculation);
  yield takeEvery('CALCULATE_PROPOSALS_START_QUEUED', handleCalculation);
  yield takeEvery('CALCULATE_PROPOSALS_SUCCESS', handleCalculationSuccess);
  yield takeEvery('CALCULATE_PROPOSALS_FAILURE', handleCalculationSuccess);
}

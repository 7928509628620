export const ADD_PROJECT_TO_TENANT_START = 'ADD_PROJECT_TO_TENANT_START';
export const ADD_PROJECT_TO_TENANT_SUCCESS = 'ADD_PROJECT_TO_TENANT_SUCCESS';
export const ADD_PROJECT_TO_TENANT_FAILURE = 'ADD_PROJECT_TO_TENANT_FAILURE';
export const RESTORE_PROJECT_START = 'RESTORE_PROJECT_START';
export const RESTORE_PROJECT_SUCCESS = 'RESTORE_PROJECT_SUCCESS';
export const RESTORE_PROJECT_FAILURE = 'RESTORE_PROJECT_SUCCESS';
export const DEACTIVATE_PROJECT_START = 'DEACTIVATE_PROJECT_START';
export const DEACTIVATE_PROJECT_SUCCESS = 'DEACTIVATE_PROJECT_SUCCESS';
export const DEACTIVATE_PROJECT_FAILURE = 'DEACTIVATE_PROJECT_FAILURE';
export const DELETE_PROJECT_START = 'DELETE_PROJECT_START';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';

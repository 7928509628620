export const getTenantId = state => state.tenant.tenant.id;
export const getTenantName = state =>
  state.tenant.tenant && state.tenant.tenant.name
    ? state.tenant.tenant.name
    : '';

export const getUserName = state => {
  if (state.profile.name) {
    if(state.profile.name === '__no_name__')
      return state.profile.email;
    return state.profile.name;
  }
  return 'User';
};

export const getHeaderMode = state => state.UI.headerMode;

export const getProjectName = state => state.project.project.name;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Auth from '../../auth/auth';
import Logger from '../../lib/util';
import Notification from './notification';
import * as config from '../../lib/config';
import { setHeaderMode } from '../header/actions';

const NotificationControls = () => {
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  const auth = new Auth();
  const logger = new Logger();

  useEffect(() => {
    getNotifications();
    const id = setInterval(getNotifications, 10000);
    return () => clearInterval(id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(setHeaderMode('notification-controls'));
    return () => dispatch(setHeaderMode(''));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getNotifications = async () => {
    const result = await fetchNotifications();

    if (result && result.notifications) {
      const sortedNotifications = result.notifications.sort((a, b) => dayjs(b.date) - dayjs(a.date));
      setNotifications(sortedNotifications);
    }
  };

  const uploadNotification = async notificationToUpload => {
    const trimmedLinks = notificationToUpload.links.map(link => ({
      title: link.title.trim(),
      url: link.url.trim(),
    }));

    const command = {
      type: 'ADD_NOTIFICATION',
      notification: {
        title: notificationToUpload.title.trim(),
        body: notificationToUpload.body.trim(),
        links: trimmedLinks,
      },
    };

    await postCommand(command);
    getNotifications();
  };

  const updateNotification = async notificationToUpdate => {
    const linksToUpload = notificationToUpdate.links.map(link => ({
      title: link.title.trim(),
      url: link.url.trim(),
    }));

    const command = {
      type: 'UPDATE_NOTIFICATION',
      notification: {
        ...notificationToUpdate,
        title: notificationToUpdate.title.trim(),
        body: notificationToUpdate.body.trim(),
        links: linksToUpload,
      },
    };

    await postCommand(command);
    getNotifications();
  };

  const deleteNotification = async id => {
    if (window.confirm('Are you sure you want to delete this notification?')) {
      const command = {
        type: 'DELETE_NOTIFICATION',
        notificationId: id,
      };

      await postCommand(command);
      getNotifications();
    }
  };

  const postCommand = async command => {
    let result;
    new Auth().checkAuth();

    await fetch(`${config.baseURL()}/api/admin/commands`, {
      method: 'post',
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(command),
    })
      .then(res => res.json())
      .then(res => {
        logger.debug('post command notification-controls api success: ', res);
        result = res;
      })
      .catch(error => {
        logger.debug('post command notification-controls api failure: ', error);
      });

    if (result) return result;
  };

  const fetchNotifications = async () => {
    new Auth().checkAuth();
    return await fetch(`${config.baseURL()}/api/admin/notifications/all`, {
      method: 'GET',
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(res => res.json())
      .then(res => {
        logger.debug(
          `get ${
            auth.isAdmin()
              ? 'admin all notifications request'
              : 'user notifications request'
          } success response: `,
          res
        );

        return res;
      })
      .catch(error => {
        logger.debug(
          `get ${
            auth.isAdmin()
              ? 'admin all notifications request'
              : 'user notifications request'
          } failure response: `,
          error
        );
      });
  };

  return (
    <NotificationControlsMain>
      <NewNotificationsContainer>
        <NotficationsContainerHeader>Create New Notification</NotficationsContainerHeader>
        <NotificationsContainerSubheader>
          Use this control to add a new notification.  You must provide a title and body, but can also add links.  As soon as you press the 'Add' button, the notifications are shown to all users.
        </NotificationsContainerSubheader>
        <Notification
          notification={{ title: '', body: '', links: [] }}
          uploadNotification={uploadNotification}
          newNotification
        />
      </NewNotificationsContainer>
      <NotificationsCategory>
        <NotficationsContainerHeader>Active Notifications</NotficationsContainerHeader>
        <ActiveNotificationsSubheader>The following are the notifications actively shown to users until they dismiss them.</ActiveNotificationsSubheader>
        <NotificationsContainer>
          {notifications.length > 0 ? 
          notifications.map(notification => (
            <Notification
              notification={notification}
              deleteNotification={deleteNotification}
              updateNotification={updateNotification}
              key={`notification-controls-${notification.id}`}
            />
          )) : <div>There are no notifications</div>}
        </NotificationsContainer>
      </NotificationsCategory>
    </NotificationControlsMain>
  );
};

export default NotificationControls;

const NotificationControlsMain = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 360px 360px;
  grid-column-gap: 40px;
  align-items: flex-start;
  margin: 104px auto auto auto;
  padding: 0 0 25px 0;
  @media (max-width: 768px) {
    grid-auto-flow: row;
    grid-auto-columns: 360px;
  }
`;

const NotficationsContainerHeader = styled.h1`
  position: sticky;
  top: 55px;
  width: 370px;
  background-color: #fafafa;
  padding: 22px 0 22px 5px;
  margin: 0 0 0 -5px;
`;

const NotificationsContainerSubheader = styled.h2`
  color: var(--color-grey);
  font-size: smaller;
  font-style: italic;
  margin-top: 0px;
`;

const ActiveNotificationsSubheader = styled(NotificationsContainerSubheader)`
  margin-bottom: 25px;
`;

const NotificationsCategory = styled.div`
  position: sticky;
  top: 0;
`;

const NewNotificationsContainer = styled(NotificationsCategory)`
  top: 55px;
`;

const NotificationsContainer = styled.div``;
